import React, { HTMLAttributes, useCallback } from "react"
import styled from "../context/theme"
import { memo } from "react"
import { useModalStore } from "~context/stores"

export const Backdrop = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
`

const StyledModal = styled.div`
  position: absolute;
  max-width: 420px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  background-color: white;
  z-index: 9001;
`

const CloseButton = styled.span`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url("images/close-black.svg");
  background-size: cover;
  z-index: 9002;
  cursor: pointer;
`

export const Modal: React.FC<
  HTMLAttributes<HTMLElement> & {
    closable?: boolean
  }
> = memo(({ children, closable, ...props }) => {
  const { closeModal } = useModalStore()

  const handleClick = useCallback(e => {
    e.stopPropagation()
  }, [])

  const handleClose = useCallback(e => {
    e.stopPropagation()
    closeModal()
  }, [])

  return (
    <StyledModal onClick={handleClick} {...props}>
      {closable && <CloseButton onClick={handleClose} />}
      {children}
    </StyledModal>
  )
})
