import * as yup from "yup"

export const addressScheme = yup.object().shape({
  address_common: yup.string().required("필수 항목입니다."),
  address_append: yup.string(),
  postal_code: yup.string().email().required("필수 항목입니다."),
  name: yup.string().required("필수 항목입니다."),
})

export const checkAuthScheme = yup.object().shape({
  email: yup
    .string()
    .email("올바른 이메일이 아닙니다.")
    .required("필수 항목입니다."),
})

export const increaseAmountScheme = yup.object().shape({
  amount: yup.number().required("필수 항목입니다.").min(11000),
})

export const joinPeopleNewScheme = yup.object().shape({
  amount: yup.number().min(11000).required("필수 정보입니다."),
  nickname: yup
    .string()
    .required("필수 항목입니다.")
    .max(10, "닉네임은 10자 이내로 입력해주세요."),
  phone: yup
    .string()
    .required("필수 항목입니다.")
    .min(9, "휴대폰번호를 정확히 입력해주세요.")
    .length(11, "휴대폰번호를 정확히 입력해주세요."),
  card_number: yup
    .string()
    .required("필수 항목입니다.")
    .min(14, "카드번호를 확인해주세요.")
    .length(16, "카드번호를 확인해주세요."),
  card_expiry: yup
    .string()
    .required("필수 항목입니다.")
    .length(4, "카드유효기간을 확인해주세요."),
  card_password: yup
    .string()
    .required("필수 항목입니다.")
    .length(2, "비밀번호 앞 2자리를 확인해주세요."),
  card_birthday: yup
    .string()
    .required("필수 항목입니다.")
    .length(6, "생년월일 6자리를 확인해주세요."),
  notGoods: yup.boolean(),
})

export const joinPeopleExistedScheme = yup.object().shape({
  amount: yup.number().min(11000).required("필수 정보입니다."),
  card_number: yup
    .string()
    .required("필수 항목입니다.")
    .min(14, "카드번호를 확인해주세요.")
    .length(16, "카드번호를 확인해주세요."),
  card_expiry: yup
    .string()
    .required("필수 항목입니다.")
    .length(4, "카드유효기간을 확인해주세요."),
  card_password: yup
    .string()
    .required("필수 항목입니다.")
    .length(2, "비밀번호 앞 2자리를 확인해주세요."),
  card_birthday: yup
    .string()
    .required("필수 항목입니다.")
    .length(6, "생년월일 6자리를 확인해주세요."),
  notGoods: yup.boolean(),
})

export const joinPeopleExistedWithExistingPaymentMethodScheme = yup
  .object()
  .shape({
    amount: yup.number().min(11000).required("필수 정보입니다."),
    notGoods: yup.boolean(),
  })

export const loginScheme = yup.object().shape({
  email: yup
    .string()
    .email("올바른 이메일이 아닙니다.")
    .required("필수 항목입니다."),
  password: yup.string().required(),
})
