import React, { HTMLAttributes, memo } from "react"
import { keyframes } from "@emotion/core"
import { Br, InnerSection as InnerSectionOrig } from "../components/common"
import styled from "../context/theme"

/* The typing effect */
const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`
const blinkCursor = keyframes`
  from, to { border-color: transparent }
  50% { border-color: orange; }
`

const Self = styled.section`
  position: relative;
  background-color: black;
`

const InnerSection = styled(InnerSectionOrig)`
  label: InnerSection;
`

const CircleWrapper = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  label: CircleWrapper;

  ${({ reverse }) =>
    reverse &&
    `
      flex-direction: row-reverse;
  `}


  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    flex-direction: column;
  }
`

const Circle = styled.div<{ image: string; reverse: boolean }>`
  width: 350px;
  height: 350px;
  border-radius: 175px;
  background-color: white;
  background-image: url("images/${({ image }) => image}");
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    width: 176px;
    height: 176px;
    
    ${({ reverse }) =>
      reverse &&
      `
    align-self: flex-end;
  `}
  }
`

const CircleTitleWrapper = styled.div<{ reverse: boolean }>`
  max-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    margin-top: 20px;

    ${({ reverse }) =>
      reverse &&
      `
    align-self: flex-end;
  `}
  }
`

const CircleTitle = styled.div`
  color: white;
  white-space: pre-wrap;
  text-align: left;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    white-space: normal;
  }
`

const Dot = styled.div`
  width: 9px;
  height: 9px;
  background-image: url("images/dot.svg");
  background-size: cover;
  margin-bottom: 12px;
`

const CircleContainer: React.FC<{
  image: string
  reverse: boolean
  title: string
}> = memo(({ image, reverse, title }) => {
  return (
    <CircleWrapper reverse={reverse}>
      <Circle image={image} reverse={reverse} />
      <CircleTitleWrapper reverse={reverse}>
        <CircleTitle>
          <Dot />
          {title}
        </CircleTitle>
      </CircleTitleWrapper>
    </CircleWrapper>
  )
})

const SectionWrapper = styled.section`
  text-align: center;
  position: relative;
  background-color: #140a23;
  label: SectionWrapper;
  /* 
  overflow-x: hidden;
  perspective: 1px;
  perspective-origin: 0 0;
  transform-style: preserve-3d;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(3);
    transform-origin: 0 0;
    z-index: -1;
    transform-style: inherit;
  } */
`

const TitleTypo = styled.h2`
  color: white;
  font-size: 43px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: -1px;
  margin-top: 40px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 26px;
    line-height: 40px;
    margin-top: 30px;
    white-space: pre-wrap;
  }
`

const SubTitleTypo = styled.h3`
  color: white;
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: -0.5px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
  }
`

const TitleKeyword = styled.span`
  color: ${({ theme }) => theme.colors.purple2};

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    white-space: pre-wrap;
  }
`

const WeDidTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  padding-top: 40px;
  padding-bottom: 136px;
  width: 100%;
  height: 500px;
  z-index: 100;
  background-color: transparent;
  text-align: left;
  background-image: linear-gradient(
    to bottom,
    #000000 29%,
    rgba(0, 0, 0, 0.7) 59%,
    rgba(0, 0, 0, 0.39) 79%,
    rgba(0, 0, 0, 0.13) 92%,
    rgba(0, 0, 0, 0)
  );

  /* linear-gradient(
    to bottom,
    #000000 29%,
    rgba(0, 0, 0, 0.7) 51%,
    rgba(0, 0, 0, 0.39) 68%,
    rgba(0, 0, 0, 0) 89%
  ); */

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    height: 383px;
  }
`

const WeDidWrapper = styled.div<{ index: number }>`
  display: flex;
  justify-content: center;
  text-align: center;

  &:not(:first-of-type) {
    margin-top: 150px;
  }

  label: WeDidWrapper;
`

const WeDids = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label: WeDids;
`

const WeDidList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -40vh;
  padding-top: 80vh;
  label: WeDidList;
`

const WeDid: React.FC<
  HTMLAttributes<HTMLElement> & {
    index: number
  }
> = memo(({ children, index }) => {
  return <WeDidWrapper index={index}>{children}</WeDidWrapper>
})

const WeDidTitle: React.FC<{ keyword: string }> = memo(({ keyword = "" }) => {
  return (
    <WeDidTitleWrapper>
      <InnerSection>
        <SubTitleTypo>우리가 살아갈 세상은 달라야 하니까,</SubTitleTypo>
        <TitleTypo>
          닷페이스는
          <br />
          <TitleKeyword>{keyword}</TitleKeyword>
          <br />에 대한 이야기를 <Br mobile />
          해왔습니다.
        </TitleTypo>
      </InnerSection>
    </WeDidTitleWrapper>
  )
})

const WeDidsGradientBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    #140a23,
    #140a23 23%,
    #030206 38%
  );
`

const ButWeDidntWrapper = styled.div`
  position: relative;
  height: 200vh;
  margin-top: 50vh;
  display: flex;
  justify-content: center;
  label: ButWeDidntWrapper;
`

const ButWeDidntInner = styled.div`
  height: 50vh;
  position: sticky;
  top: 40vh;
  text-align: left;
`

const ButWeDidntTitle = styled.h2`
  font-size: 37px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: -1px;
  color: white;
  text-align: center;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 26px;
    line-height: 40px;
    letter-spacing: -1px;
    white-space: pre-wrap;
    text-align: center;
  }
`

const ButWeDidnt: React.FC<{ text: any }> = memo(({ text }) => {
  return (
    <ButWeDidntWrapper>
      <ButWeDidntInner>{text}</ButWeDidntInner>
    </ButWeDidntWrapper>
  )
})

const Section: React.FC<{
  contents: { title: string }[]
  images: string[]
  keyword: string
  weDidnts: any
}> = memo(({ contents = [], images, keyword = "", weDidnts = "" }) => {
  return (
    <SectionWrapper>
      <WeDids>
        <WeDidTitle keyword={keyword} />
        <WeDidList>
          <InnerSection>
            {contents.map((c, index) => (
              <WeDid index={index}>
                <CircleContainer
                  image={images[index]}
                  reverse={index % 2 === 1}
                  title={c.title}
                />
              </WeDid>
            ))}
          </InnerSection>
        </WeDidList>
      </WeDids>
      <WeDidsGradientBottom />
      <ButWeDidnt text={weDidnts} />
    </SectionWrapper>
  )
})

type Content = {
  title: string
}

const contents1: Content[] = [
  {
    title: "2016년, 성소수자 부모모임의\n이야기를 500만명에게 전하고",
  },
  {
    title: "8만명의 연인, 가족, 친구들과\n온라인 퀴퍼에 함께하고",
  },
  {
    title: "행복한 신혼 커플의 이야기를 전했지만",
  },
]

const contents2: Content[] = [
  {
    title: "성착취는 개인의 범죄일 뿐 아니라\n조직적, 산업적 착취라고,",
  },
  {
    title: "우리는 가해자들에게 더 많은\n질문을 해야 한다 말해왔지만 ",
  },
]

const contents3: Content[] = [
  {
    title:
      "취객에게 목을 졸려도, 성희롱과\n폭력을 당해도 참아야 했던\n사람들에 대해 말하고",
  },
  {
    title: "신규 간호사의 죽음에 대해\n200만명에게 전했지만",
  },
]

const contents4: Content[] = [
  {
    title: "시설을 나오기로 한 자매의\n이야기를 전하고",
  },
  {
    title: "자유롭게 거리를 걷는\n'프라이드'에 대해 이야기했지만",
  },
]

const contents5: Content[] = [
  {
    title: "검은 시위로 우리가 광장에 나왔을 때",
  },
  {
    title:
      "헌법재판소에서 낙태죄\n'헌법 불합치' 판결이 났을 때,\n우리는 함께 그 소식을 전했지만",
  },
]

const contents6: Content[] = [
  {
    title: "우리는 플라스틱 사용을 줄여야 한다고",
  },
  {
    title: "아니 우리가 살아가는 방식을\n완전히 바꿔야만 한다고 이야기했지만",
  },
]

const images1: string[] = ["q-1.jpg", "q-2.jpg", "q-3.jpg"]
const images2: string[] = ["n-1.jpg", "n-2.jpg"]
const images3: string[] = ["w-1.jpg", "w-2.jpg"]
const images4: string[] = ["d-1.jpg", "d-2.jpg"]
const images5: string[] = ["wo-1.jpg", "wo-2.jpg"]
const images6: string[] = ["e-1.jpg", "e-2.jpg", "e-3.jpg"]

const weDidnts1 = (
  <ButWeDidntTitle>
    우리는 아직
    <Br desktop mobile />
    이들이 법적으로 <Br mobile />
    가족이 되었다는
    <Br desktop mobile />
    이야기를 전하진 못했습니다.
  </ButWeDidntTitle>
)

const weDidnts2 = (
  <ButWeDidntTitle>
    우리는 아직
    <Br mobile desktop />
    피해 경험자가 되찾아야 할 <Br mobile />
    일상에 대해
    <Br desktop mobile />더 이야기 하지 못했습니다.
  </ButWeDidntTitle>
)

const text3 = "더이상 버티지 않아도\n되는 세상이 왔다는"
const weDidnts3 = (
  <ButWeDidntTitle>
    우리는 아직
    <br />
    {text3}
    <br />
    이야기를 전하진 못했습니다.
  </ButWeDidntTitle>
)

const weDidnts4 = (
  <ButWeDidntTitle>
    우리는 아직
    <br />
    시설 밖의 자유에 대한
    <br />
    이야기를 전하진 못했습니다.
  </ButWeDidntTitle>
)

const weDidnts5 = (
  <ButWeDidntTitle>
    우리는 아직
    <br />
    낙태죄가 사라지고 <Br mobile />
    세상이 바뀌었다는
    <br />
    이야기를 전하진 못했습니다.
  </ButWeDidntTitle>
)

const weDidnts6 = (
  <ButWeDidntTitle>
    우리는 아직
    <br />
    기후 위기에 함께 <Br mobile />
    대처할 방법을
    <br />
    전하진 못했습니다.
  </ButWeDidntTitle>
)

const keyword3 = "일터에서 죽고 다치고\n견디는 사람들"
const keyword5 = "임신 중지와\n여성의 재생산권리"

export default memo(({ ...props }) => (
  <Self {...props}>
    <Section
      key="section1"
      contents={contents1}
      images={images1}
      keyword="퀴어와 가족"
      weDidnts={weDidnts1}
    />
    <Section
      key="section2"
      contents={contents2}
      images={images2}
      keyword="디지털 성착취"
      weDidnts={weDidnts2}
    />
    <Section
      key="section3"
      contents={contents3}
      images={images3}
      keyword={keyword3}
      weDidnts={weDidnts3}
    />
    <Section
      key="section4"
      contents={contents4}
      images={images4}
      keyword="장애와 자유"
      weDidnts={weDidnts4}
    />
    <Section
      key="section5"
      contents={contents5}
      images={images5}
      keyword={keyword5}
      weDidnts={weDidnts5}
    />
    <Section
      key="section6"
      contents={contents6}
      images={images6}
      keyword="환경과 기후 위기"
      weDidnts={weDidnts6}
    />
  </Self>
))
