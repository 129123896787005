import React, { HTMLAttributes, memo, useCallback, useRef } from "react"
import { useModalStore } from "~context/stores"
import styled from "~context/theme"
import { Modal } from "../modals"

const Self = styled(Modal)`
  max-width: 300px;
  border-radius: 20px;
`

const InnerSection = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Title = styled.div`
  width: 100%;
  padding: 13px 13px 15px 13px;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 rgba(108, 25, 188, 0.3);
  background-color: #ffffff;
  font-size: 23px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  color: #1f053c;
`

const Body = styled.div`
  padding: 55px 30px 43px;
  background-image: linear-gradient(245deg, #ddcaa6, #bb6ae5);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const ContributeDescription = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: center;
  color: white;
  margin-top: 20px;
`

const ContributeButton = styled.button`
  position: absolute;
  bottom: -70px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  background-image: linear-gradient(268deg, #8f00ff, #6c19bc);
  width: 100%;
  padding-top: 16px;
  padding-bottom: 17px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 53px 50px;
  border-radius: 35px;
  box-shadow: 0 -1px 30px 5px rgba(130, 63, 194, 0.15);
  background-color: #f8f7fa;
`

const FooterTitle = styled.h5`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #1f053c;
`

const FooterDescription = styled.p`
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #1f053c;
  margin-top: 8px;
`

const CopyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: solid 1px #e8e6eb;
  background-color: #ffffff;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #823fc2;
  padding-top: 16px;
  padding-bottom: 17px;
  cursor: pointer;
`

const CloseButton = styled.span`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url("images/close.svg");
  background-size: cover;
  z-index: 9002;
  cursor: pointer;
`

const ShareEventModal: React.FC<
  HTMLAttributes<HTMLElement> & {
    onClickContribute: () => void
    text?: string
  }
> = memo(({ onClickContribute, text }) => {
  const { closeModal } = useModalStore()
  const copyRef = useRef(null)
  const handleClickContribute = useCallback(() => {
    closeModal()
    onClickContribute()
  }, [closeModal, onClickContribute])

  const handleCopy = useCallback(() => {
    const _text = `2021년 내가 듣고싶은 이야기는 "${text}"\n<https://2021.dotface.kr>\n#닷페이스 #연말후원캠페인 #내가살아갈2021년`
    const textarea = document.createElement("textarea")
    textarea.value = _text
    document.body.appendChild(textarea)
    textarea.select()
    textarea.setSelectionRange(0, 9999)
    document.execCommand("copy")
    document.body.removeChild(textarea)
    window.alert(`${_text}\n\n위 내용이 복사되었습니다.`)
  }, [text])

  const handleClose = useCallback(e => {
    e.stopPropagation()
    closeModal()
  }, [])

  return (
    <Self>
      <CloseButton onClick={handleClose} />
      <Body>
        <InnerSection>
          <Title>{text}</Title>
          <textarea hidden ref={copyRef}></textarea>
          <ContributeDescription>
            닷페이스가
            <br />
            앞으로 이 소식을 전하려면
            <br />
            당신의 힘이 필요합니다.
          </ContributeDescription>
          <ContributeButton onClick={handleClickContribute}>
            닷페이스 후원하기
          </ContributeButton>
        </InnerSection>
      </Body>
      <Footer>
        <InnerSection>
          <FooterTitle>'공유'도 큰 힘이 됩니다.</FooterTitle>
          <FooterDescription>
            ‘닷페를 통해 듣고 싶은 소식’을 더 많은
            <br />
            사람들이 대답할 수 있게 공유해주세요.
          </FooterDescription>
          <CopyButton onClick={handleCopy}>내용 복사하기</CopyButton>
          {/* <SnsButtons>
            <SnsButton image="images/fb.svg" onClick={handleClickFb} />
            <SnsButton
              image="images/twitter.svg"
              onClick={handleClickTwitter}
            />
          </SnsButtons> */}
        </InnerSection>
      </Footer>
    </Self>
  )
})

export default ShareEventModal
