import React, { memo, useEffect, useState } from "react"
import Bowser from "bowser"
import { css } from "@emotion/core"
import { Br } from "~components/common"
import styled from "~context/theme"
import { useLoadingStore } from "~context/stores"

const Self = styled.section<{ loading; webpSupport }>`
  height: 100vh;
  background-color: black;
  background-size: cover;
  background-position: center;

  ${({ loading, webpSupport }) =>
    !loading &&
    css`
      background-image: url(${webpSupport
        ? "images/jumbotron-desktop.webp"
        : "images/jumbotron-desktop.mp4"});
    `}

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    white-space: pre-wrap;

    ${({ loading, webpSupport }) =>
      !loading &&
      css`
        background-image: url(${webpSupport
          ? "images/jumbotron-mobile.webp"
          : "images/jumbotron-mobile.mp4"});
      `}
  }
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleWrapper = styled.div`
  text-align: center;
`

const Title = styled.h1`
  color: white;
  font-size: 50px;
  font-weight: bold;
  line-height: 70px;
  letter-spacing: -1px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -1px;
  }
`

const Keywords = styled.div`
  color: white;
  font-size: 18px;
  line-height: 32px;
  margin-top: 20px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    margin-top: 30px;
    white-space: pre-wrap;
  }
`

const ArrowDown = styled.div`
  width: 35px;
  height: 16px;
  background-image: url("images/jumbotron-arrow-down-desktop.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 60px;
`

const Jumbotron = memo(props => {
  const { state: loadingState } = useLoadingStore()
  const [webpSupport, setWebpSupport] = useState(true)
  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const name = browser.getBrowserName()

    if (name === "Safari") {
      setWebpSupport(false)
    }
  }, [setWebpSupport])

  return (
    <Self loading={loadingState.show} webpSupport={webpSupport} {...props}>
      <Wrapper>
        <TitleWrapper>
          <Title>
            우리가 살아갈 <Br mobile />
            2021년은
            <Br desktop mobile />
            달라야 하니까
          </Title>
          <Keywords>
            2021년 닷페이스 연말연초
            <Br mobile />
            후원캠페인
          </Keywords>
        </TitleWrapper>
        <ArrowDown />
      </Wrapper>
    </Self>
  )
})

export default Jumbotron
