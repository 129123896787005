import React, { memo, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "~components/layout"

const Type1: React.FC = memo(() => {
  const ogImage =
    "https://dotfaceupload.s3.ap-northeast-2.amazonaws.com/2020/plastic-ogp.jpg"

  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 2000)
  }, [])

  return (
    <Layout>
      <Helmet>
        <meta property="og:image" content={ogImage} />
      </Helmet>
    </Layout>
  )
})

export default Type1

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
