import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useReducer,
} from "react"
import { useContext } from "react"
import * as api from "~utils/api"

export type CardInfo = {
  card_number?: string
  card_expiry?: string
  card_password?: string
  card_birthday?: string
}

enum ACTION_TYPES {
  ALREADY_APPLIED = "ALREADY_APPLIED",
  CHANGE_ADDRESS = "CHANGE_ADDRESS",
  CHANGE_APPLICATION = "CHANGE_APPLICATION",
  CHANGE_CARD = "CHANGE_CARD",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHECKED_EMAIL_FOR_NEW_USER = "CHECKED_EMAIL_FOR_NEW_USER",
  CONGRATURATION = "CONGRATURATION",
  CONGRATURATION_FOR_NEW_PEOPLE = "CONGRATURATION_FOR_NEW_PEOPLE",
  CONGRATURATION_FOR_PEOPLE = "CONGRATURATION_FOR_PEOPLE",
  NEED_OTHER_METHOD = "NEED_OTHER_METHOD",
  NEED_SIGN_UP = "NEED_SIGN_UP",
  LOGIN_WITH_EMAIL = "LOGIN_WITH_EMAIL",
  LOGIN_WITH_FACEBOOK = "LOGIN_WITH_FACEBOOK",
  LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD",
  LOGGED_IN = "LOGGED_IN",
  NEED_ADDRESS = "NEED_ADDRESS",
  NEED_ADDRESS_FOR_NEW_PEOPLE = "NEED_ADDRESS_FOR_NEW_PEOPLE",
  SENT_EMAIL = "SENT_EMAIL",
  INIT = "INIT",
  GO_BACK = "GO_BACK",
}

export enum NEW_USER_STEP {
  INITIAL = "INITIAL",
  CHECKED_EMAIL = "CHECKED_EMAIL",
  NEED_ADDRESS = "NEED_ADDRESS",
  CONGRATURATION = "CONGRATURATION",
}

export enum EXISTING_USER_STEP {
  INITIAL = "INITIAL",
  SENT_EMAIL = "SENT_EMAIL",
  NEED_ADDRESS = "NEED_ADDRESS",
  NEED_OTHER_METHOD = "NEED_OTHER_METHOD",
  NEED_SIGN_UP = "NEED_SIGN_UP",
  LOGGED_IN = "LOGGED_IN",
  CONGRATURATION = "CONGRATURATION",
  ALREADY_PEOPLE = "ALREADY_PEOPLE",
  CONGRATURATION_FOR_PEOPLE = "CONGRATURATION_FOR_PEOPLE",
  ALREADY_APPLIED = "ALREADY_APPLIED",
}

type Action =
  | { type: ACTION_TYPES.CHECKED_EMAIL_FOR_NEW_USER }
  | { type: ACTION_TYPES.CONGRATURATION }
  | { type: ACTION_TYPES.CONGRATURATION_FOR_NEW_PEOPLE }
  | { type: ACTION_TYPES.CONGRATURATION_FOR_PEOPLE }
  | { type: ACTION_TYPES.INIT }
  | { type: ACTION_TYPES.SENT_EMAIL }
  | { type: ACTION_TYPES.NEED_ADDRESS; updateAddressToken: string }
  | {
      type: ACTION_TYPES.NEED_ADDRESS_FOR_NEW_PEOPLE
      updateAddressToken: string
    }
  | { type: ACTION_TYPES.NEED_OTHER_METHOD }
  | { type: ACTION_TYPES.NEED_SIGN_UP }
  | { type: ACTION_TYPES.LOGIN_WITH_EMAIL }
  | { type: ACTION_TYPES.LOGIN_WITH_FACEBOOK }
  | { type: ACTION_TYPES.LOGIN_WITH_PASSWORD }
  | {
      type: ACTION_TYPES.LOGGED_IN
      amount?: number
      user: any
      paymentMethod: any
      token: string
      membership?: Membership
      address?: Address
      existingApplication?: {
        goods?: boolean
      }
    }
  | {
      type: ACTION_TYPES.CHANGE_ADDRESS
      address_common?: string
      address_apend?: string
      postal_code?: string
      name?: string
    }
  | {
      type: ACTION_TYPES.CHANGE_APPLICATION
      amount?: number
      nickname?: string
      phone?: string
      notGoods?: boolean
    }
  | {
      type: ACTION_TYPES.CHANGE_CARD
      card: CardInfo
    }
  | {
      type: ACTION_TYPES.CHANGE_EMAIL
      email: string
    }
  | {
      type: ACTION_TYPES.CHANGE_PASSWORD
      password: string
    }
  | {
      type: ACTION_TYPES.GO_BACK
      newUser: boolean
    }

const INIT_ADDRESS = {
  address_common: "",
  address_append: "",
  postal_code: "",
  name: "",
}

const INIT_APPLICATION = {
  amount: 11000,
  email: "",
  nickname: "",
  phone: "",
  card: {
    card_number: "",
    card_expiry: "",
    card_password: "",
    card_birthday: "",
  },
  notGoods: false,
}

interface ExistingUserState {
  password?: string
  facebookId?: string
}

interface Application {
  amount: number
  email: string
  nickname: string
  phone: string
  card: CardInfo
  notGoods: boolean
}

type Address = {
  address_common: string
  address_append?: string
  postal_code: string
  name: string
}

type Membership = {
  amount: number
}

interface State {
  newUser: boolean
  newUserStep: NEW_USER_STEP
  existingUserStep: EXISTING_USER_STEP
  existingUserState: ExistingUserState
  paymentMethod?: any
  token: string
  updateAddressToken?: string
  application: Application
  address: Address
  currentMembership: Membership
}

const INITIAL_STATE: State = {
  newUser: true,
  newUserStep: NEW_USER_STEP.INITIAL,
  existingUserStep: EXISTING_USER_STEP.INITIAL,
  existingUserState: {},
  paymentMethod: {},
  token: "",
  updateAddressToken: "",
  application: INIT_APPLICATION,
  address: INIT_ADDRESS,
  currentMembership: { amount: 0 },
}

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.CHECKED_EMAIL_FOR_NEW_USER:
      return {
        ...prevState,
        newUserStep: NEW_USER_STEP.CHECKED_EMAIL,
      }
    case ACTION_TYPES.CHANGE_ADDRESS:
      return {
        ...prevState,
        address: {
          ...prevState.address,
          ...action,
        },
      }
    case ACTION_TYPES.CHANGE_APPLICATION:
      return {
        ...prevState,
        application: {
          ...prevState.application,
          ...action,
        },
      }
    case ACTION_TYPES.CHANGE_CARD:
      return {
        ...prevState,
        application: {
          ...prevState.application,
          card: {
            ...prevState.application.card,
            ...action.card,
          },
        },
      }
    case ACTION_TYPES.CHANGE_EMAIL:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.INITIAL,
        application: {
          ...prevState.application,
          email: action.email,
        },
      }
    case ACTION_TYPES.CHANGE_PASSWORD:
      return {
        ...prevState,
        existingUserState: {
          ...prevState.existingUserState,
          password: action.password,
        },
      }
    case ACTION_TYPES.CONGRATURATION:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.CONGRATURATION,
      }
    case ACTION_TYPES.CONGRATURATION_FOR_NEW_PEOPLE:
      return {
        ...prevState,
        newUserStep: NEW_USER_STEP.CONGRATURATION,
      }
    case ACTION_TYPES.CONGRATURATION_FOR_PEOPLE:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.CONGRATURATION_FOR_PEOPLE,
      }
    case ACTION_TYPES.LOGGED_IN:
      const isMembership = action.user.membership
      let STEP = EXISTING_USER_STEP.LOGGED_IN
      if (isMembership) {
        STEP = EXISTING_USER_STEP.ALREADY_PEOPLE
      }
      if (action.existingApplication) {
        STEP = EXISTING_USER_STEP.ALREADY_APPLIED
      }

      return {
        ...prevState,
        currentMembership: action.membership,
        existingUserStep: STEP,
        paymentMethod: action.paymentMethod,
        token: action.token,
        address: {
          ...prevState.address,
          ...action.address,
        },
        application: {
          ...prevState.application,
          ...action.user,
          amount: isMembership ? action.membership.amount : 11000,
          notGoods: action.existingApplication
            ? action.existingApplication.goods
            : false,
        },
      }
    case ACTION_TYPES.NEED_ADDRESS:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.NEED_ADDRESS,
        updateAddressToken: action.updateAddressToken,
      }
    case ACTION_TYPES.NEED_ADDRESS_FOR_NEW_PEOPLE:
      return {
        ...prevState,
        newUserStep: NEW_USER_STEP.NEED_ADDRESS,
        updateAddressToken: action.updateAddressToken,
      }
    case ACTION_TYPES.NEED_OTHER_METHOD:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.NEED_OTHER_METHOD,
      }
    case ACTION_TYPES.NEED_SIGN_UP:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.NEED_SIGN_UP,
      }
    case ACTION_TYPES.SENT_EMAIL:
      return {
        ...prevState,
        existingUserStep: EXISTING_USER_STEP.SENT_EMAIL,
      }
    case ACTION_TYPES.GO_BACK:
      const { newUser } = action
      if (newUser) {
        switch (prevState.newUserStep) {
          case NEW_USER_STEP.CHECKED_EMAIL:
            return INITIAL_STATE
          default:
            return {
              ...prevState,
            }
        }
      } else {
        switch (prevState.existingUserStep) {
          case EXISTING_USER_STEP.LOGGED_IN:
            return INITIAL_STATE
          default:
            return {
              ...prevState,
            }
        }
      }
    case ACTION_TYPES.INIT:
    default:
      return INITIAL_STATE
  }
}

export const Context = createContext<{
  state: typeof INITIAL_STATE
  dispatch: (action: Action) => void
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
})

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  )
}

export const useContributeStore = () => {
  const { state, dispatch } = useContext(Context)

  const backward = useCallback(
    (newUser: boolean) => {
      dispatch({
        type: ACTION_TYPES.GO_BACK,
        newUser,
      })
    },
    [state.newUserStep, state.existingUserStep]
  )

  const changeCard = useCallback((card: CardInfo) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_CARD,
      card,
    })
  }, [])

  const changeEmail = useCallback((email: string) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_EMAIL,
      email,
    })
  }, [])

  const changePassword = useCallback((password: string) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_PASSWORD,
      password,
    })
  }, [])

  const checkEmail = useCallback(async (email: string) => {
    const result = await api.loginWithEmailRequest(email)
    if (result.success) {
      dispatch({
        type: ACTION_TYPES.SENT_EMAIL,
      })
    } else if (result.errors) {
      if (result.errors.user) {
        dispatch({
          type: ACTION_TYPES.NEED_SIGN_UP,
        })
      }

      if (result.errors.method) {
        dispatch({
          type: ACTION_TYPES.NEED_OTHER_METHOD,
        })
      }
    } else {
      window.alert(
        "알 수 없는 에러가 발생했어요! 다시 시도해주세요. 계속 안되면 연락주세요!"
      )
    }
    return result
  }, [])

  const checkEmailForNewUser = useCallback(async () => {
    const result = await api.checkEmailExists(state.application.email)
    if (result.errors.user[0] === "") {
      dispatch({
        type: ACTION_TYPES.CHECKED_EMAIL_FOR_NEW_USER,
      })
    }
    return result
  }, [state.application.email])

  const changeAddress = useCallback(
    (props: {
      address_common?: string
      address_append?: string
      postal_code?: string
      name?: string
    }) => {
      dispatch({
        type: ACTION_TYPES.CHANGE_ADDRESS,
        ...props,
      })
    },
    [dispatch]
  )

  const changeApplication = useCallback(
    (props: {
      amount?: number
      nickname?: string
      notGoods?: boolean
      phone?: string
    }) => {
      dispatch({
        type: ACTION_TYPES.CHANGE_APPLICATION,
        ...props,
      })
    },
    [dispatch]
  )

  const increaseAmount = useCallback(async () => {
    try {
      const result = await api.increaseAmount({
        amount: state.application.amount,
        token: state.token,
        address: state.address,
      })

      if (result.success) {
        dispatch({
          type: ACTION_TYPES.CONGRATURATION_FOR_PEOPLE,
        })
      }

      return result
    } catch (e) {
      throw e
    }
  }, [state.application.amount, state.address, state.token])

  const loginWithEmail = useCallback(
    async (emailToken: string) => {
      try {
        const result = await api.loginWithEmail(emailToken)

        if (result.success) {
          dispatch({
            type: ACTION_TYPES.LOGGED_IN,
            membership: result.data.membership,
            user: result.data.user,
            paymentMethod: result.data.payment_method,
            token: result.data.token,
            existingApplication: result.data.application,
            address: result.data.address,
          })
        }

        return result
      } catch (e) {
        throw e
      }
    },
    [state.application.email, state.existingUserState.password]
  )

  const loginWithFb = useCallback(
    async (accessToken: string) => {
      try {
        const result = await api.loginWithFb({ accessToken })

        if (result.success) {
          dispatch({
            existingApplication: result.data.application,
            type: ACTION_TYPES.LOGGED_IN,
            membership: result.data.membership,
            user: result.data.user,
            paymentMethod: result.data.payment_method,
            token: result.data.token,
            address: result.data.address,
          })
        }

        return result
      } catch (e) {
        throw e
      }
    },
    [state.application.email, state.existingUserState.password]
  )

  const loginWithPassword = useCallback(async () => {
    try {
      const result = await api.login(
        state.application.email,
        state.existingUserState.password
      )

      if (result.success) {
        dispatch({
          existingApplication: result.data.application,
          type: ACTION_TYPES.LOGGED_IN,
          membership: result.data.membership,
          user: result.data.user,
          paymentMethod: result.data.payment_method,
          token: result.data.token,
          address: result.data.address,
        })
      }

      return result
    } catch (e) {
      throw e
    }
  }, [state.application.email, state.existingUserState.password])

  const apply = useCallback(async () => {
    try {
      const result = await api.apply({
        ...state.application,
        address: state.address,
        token: state.token,
      })

      if (result.success) {
        dispatch({
          type: ACTION_TYPES.CONGRATURATION_FOR_NEW_PEOPLE,
        })
      }

      return result
    } catch (e) {
      throw e
    }
  }, [state.application, state.address, state.token])

  const applyExisted = useCallback(
    async ({
      useExistingPaymentMethod,
    }: {
      useExistingPaymentMethod: boolean
    }) => {
      try {
        const result = await api.applyExisted({
          ...state.application,
          address: state.address,
          token: state.token,
          payment_method_id:
            useExistingPaymentMethod &&
            state.paymentMethod &&
            state.paymentMethod.id,
          card: !useExistingPaymentMethod ? state.application.card : null,
        })

        if (result.success) {
          dispatch({
            type: ACTION_TYPES.CONGRATURATION,
          })
        }

        return result
      } catch (e) {
        throw e
      }
    },
    [
      state.application,
      state.address,
      state.token,
      state.paymentMethod,
      dispatch,
    ]
  )

  const applyPeople = useCallback(async () => {
    try {
      const result = await api.applyPeople({
        amount: state.application.amount,
        ...state.address,
        token: state.token,
      })

      if (result.success) {
        dispatch({
          type: ACTION_TYPES.CONGRATURATION_FOR_PEOPLE,
        })
      }

      return result
    } catch (e) {
      throw e
    }
  }, [state.application.amount, state.address, state.token])

  const updateAddress = useCallback(
    async (newUser: boolean) => {
      try {
        const result = await api.updateAddress({
          ...state.address,
          token: state.updateAddressToken,
        })

        if (result.success) {
          dispatch({
            type: newUser
              ? ACTION_TYPES.CONGRATURATION_FOR_NEW_PEOPLE
              : ACTION_TYPES.CONGRATURATION,
          })
        }

        return result
      } catch (e) {
        throw e
      }
    },
    [state.application.amount, state.token]
  )

  const init = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.INIT,
    })
  }, [])

  return {
    apply,
    applyExisted,
    applyPeople,
    backward,
    changeAddress,
    changeApplication,
    changeCard,
    changeEmail,
    changePassword,
    checkEmail,
    checkEmailForNewUser,
    increaseAmount,
    init,
    loginWithEmail,
    loginWithFb,
    loginWithPassword,
    state,
    updateAddress,
  }
}
