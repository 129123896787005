import React, { memo } from "react"
import { InnerSection as InnerSectionOrig } from "../components/common"
import styled from "../context/theme"

const Self = styled.footer`
  display: flex;
  z-index: 6000;
  position: relative;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black1};
  padding: 60px 0 80px;
  label: Footer;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    padding: 40px 0 80px;
  }
`

const InnerSection = styled(InnerSectionOrig)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Menus = styled.ul`
  display: flex;
`

const Item = styled.li<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colors.gray1};
  font-size: 16px;
  line-height: 24px;

  ${({ bold }) =>
    bold &&
    `
  font-weight: bold;
  `}

  &:not(:nth-of-type(1)) {
    margin-left: 20px;
  }

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 14px;
  }
`

const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 14px;
  letter-spacing: -0.54px;
  margin-top: 30px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 13px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 20px;
  }
`

const Inc = styled.div`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 13px;
  margin-top: 20px;
`

export default memo(() => {
  return (
    <Self>
      <InnerSection>
        <Menus>
          <Item>
            <a
              href="https://www.notion.so/dotface/8f7a311e3d6742dca3cefff2c3dae6f5"
              target="_blank"
              title="이용약관"
            >
              이용약관
            </a>
          </Item>
          <Item bold>
            <a
              href="https://www.notion.so/dotface/de70611e21444643aca1a305050330ff"
              target="_blank"
              title="개인정보취급방침"
            >
              개인정보취급방침
            </a>
          </Item>
        </Menus>
        <Copyright>
          본 콘텐츠의 저작권은 제공사에 있으며, 이를 무단 이용하는 경우 저작권법
          등에 따라 법적 책임을 질 수 있습니다.
        </Copyright>
        <Inc>©Dotface Inc</Inc>
      </InnerSection>
    </Self>
  )
})
