import React, { memo } from "react"
import {
  InnerSection as InnerSectionOrig,
  SectionWrapper as SectionWrapperOrig,
} from "../components/common"
import styled from "../context/theme"

const Self = styled(SectionWrapperOrig)`
  position: relative;
  background-color: black;
  background-image: url("images/section-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 257px 0;
`

const DimLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
`

const InnerSection = styled(InnerSectionOrig)`
  z-index: 100;
  text-align: left;
  padding: 0 48px;
`

const YearLogo = styled.div`
  width: 369px;
  height: 133px;
  background-image: url("images/section-2-2021.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -49px;
`

const Description = styled.div`
  color: white;
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: -0.5px;
  margin-top: 40px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 18px;
    line-height: 32px;
    white-space: pre-wrap;
    margin-top: 46px;
  }
`

const DESC_ROW_1 = "2021년, 우리가 꼭 전하고 싶은\n이야기들이 있습니다."

const DESC_ROW_2 =
  "우리가 살아갈 세상은\n달라야 하니까,\n우리가 꼭 앞당겨 올 미래를,"

const DESC_ROW_3 = "우리가 아직 전하지 못한\n이야기들을 먼저 소개합니다."

export default memo(({ ...props }) => (
  <Self {...props}>
    <DimLayer />
    <InnerSection>
      <YearLogo />
      <Description>
        {DESC_ROW_1}
        <br />
        {DESC_ROW_2}
        <br />
        {DESC_ROW_3}
      </Description>
    </InnerSection>
  </Self>
))
