const API_HOST = process.env.GATSBY_API_HOST

export const fetchStories = () => {
  return fetch(`${API_HOST}/etc/instagram-stories`).then(res => res.json())
}

export const fetchPeopleCount = () => {
  return fetch("https://api.dotface.kr/api/membership/stats", {
    credentials: "same-origin",
    method: "GET",
  }).then(res => res.json())
}

export const checkEmailExists = (email: string) => {
  var formData = new FormData()
  formData.append("email", email)

  return fetch(`${API_HOST}/api/user/_exists`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const increaseAmount = ({
  amount,
  address,
  token,
}: {
  amount: number
  address: {
    address_common?: string
    address_append?: string
    name?: string
    postal_code?: string
  }
  token: string
}) => {
  const formData = new FormData()
  formData.append("amount", `${amount}`)
  formData.append("token", token)
  formData.append("address_common", address.address_common)
  formData.append("address_append", address.address_append)
  formData.append("name", address.name)
  formData.append("postal_code", address.postal_code)

  return fetch(`${API_HOST}/api/events/campaign-2020/apply/increase`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const login = (email: string, password: string) => {
  const formData = new FormData()
  formData.append("email", email)
  formData.append("password", password)
  formData.append("method", "password")

  return fetch(`${API_HOST}/api/events/campaign-2020/auth/login`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const loginWithEmail = (emailToken: string) => {
  const formData = new FormData()
  formData.append("token", emailToken)
  formData.append("method", "email")

  return fetch(`${API_HOST}/api/events/campaign-2020/auth/login`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const loginWithEmailRequest = (email: string) => {
  const formData = new FormData()
  formData.append("email", email)

  return fetch(`${API_HOST}/api/events/campaign-2020/auth/login/email`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const loginWithFb = ({ accessToken }: { accessToken: string }) => {
  const formData = new FormData()
  formData.append("method", "facebook")
  formData.append("accessToken", accessToken)

  return fetch(`${API_HOST}/api/events/campaign-2020/auth/login`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const apply = ({
  address,
  amount,
  email,
  nickname,
  phone,
  card,
  token,
  notGoods,
}: {
  amount: number
  email: string
  nickname: string
  phone: string
  card?: {
    card_number?: string
    card_expiry?: string
    card_password?: string
    card_birthday?: string
  }
  address?: {
    address_common?: string
    address_append?: string
    name?: string
    postal_code?: string
  }
  token: string
  notGoods: boolean
}) => {
  const formData = new FormData()
  formData.append("token", token)
  formData.append("amount", `${amount}`)
  formData.append("card_number", card.card_number)
  formData.append("card_expiry", card.card_expiry)
  formData.append("card_birthday", card.card_birthday)
  formData.append("card_password", card.card_password)
  formData.append("email", email)
  formData.append("nickname", nickname)
  formData.append("phone", phone)
  formData.append("notGoods", notGoods ? "1" : "0")

  if (address) {
    formData.append("address_common", address.address_common)
    formData.append("address_append", address.address_append)
    formData.append("name", address.name)
    formData.append("postal_code", address.postal_code)
  }

  return fetch(`${API_HOST}/api/events/campaign-2020/apply`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const applyExisted = ({
  address,
  amount,
  card,
  phone,
  token,
  payment_method_id,
  notGoods,
}: {
  amount: number
  phone?: string
  card?: {
    card_number?: string
    card_expiry?: string
    card_password?: string
    card_birthday?: string
  }
  address?: {
    address_common?: string
    address_append?: string
    name?: string
    postal_code?: string
  }
  token: string
  payment_method_id?: string
  notGoods: boolean
}) => {
  const formData = new FormData()
  formData.append("token", token)
  formData.append("notGoods", notGoods ? "1" : "0")
  formData.append("amount", `${amount}`)

  if (phone) {
    formData.append("phone", `${phone}`)
  }

  if (address) {
    formData.append("address_common", address.address_common)
    formData.append("address_append", address.address_append)
    formData.append("name", address.name)
    formData.append("postal_code", address.postal_code)
  }

  if (card) {
    formData.append("card_number", card.card_number)
    formData.append("card_expiry", card.card_expiry)
    formData.append("card_birthday", card.card_birthday)
    formData.append("card_password", card.card_password)
  }

  if (payment_method_id) {
    formData.append("payment_method_id", `${payment_method_id}`)
  }

  return fetch(`${API_HOST}/api/events/campaign-2020/apply/existed`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const applyPeople = ({
  amount,
  token,
}: {
  amount?: number
  token: string
}) => {
  const formData = new FormData()
  formData.append("token", token)
  formData.append("amount", `${amount}`)

  return fetch(`${API_HOST}/api/events/campaign-2020/apply/people`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const joinIntegrated = (data: {
  amount: string
  cardNumber: string
  cardBirth: string
  cardExpiry: string
  cardPassword: string
  date: number
  email: string
  nickname: string
  phone: string
}) => {
  var formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return fetch(`${API_HOST}/api/membership/integrated`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const joinIntegratedContinue = (data: {
  address_append: string
  address_common: string
  postal_code: string
  name: string
  token: string
}) => {
  var formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return fetch(`${API_HOST}/api/membership/continue`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const subscribeSteebie = (nickname: string, email: string) => {
  var formData = new FormData()
  formData.append("nickname", nickname)
  formData.append("email", email)
  return fetch(
    "https://stibee.com/api/v1.0/lists/SLSbRri_RO20gOfeJFUq9r3P2Ilt/public/subscribers",
    {
      body: formData,
      credentials: "same-origin",
      method: "POST",
    }
  ).then(res => res.text())
}

export const updateAddress = (data: {
  address_append?: string
  address_common?: string
  postal_code?: string
  name?: string
  token: string
}) => {
  var formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return fetch(`${API_HOST}/api/membership/continue`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const getStories = () => {
  return fetch(`${API_HOST}/api/events/campaign-2020/stories`, {
    credentials: "same-origin",
    method: "GET",
  }).then(res => res.json())
}

export const submitStory = (text: string) => {
  const formData = new FormData()
  formData.append("text", text)

  return fetch(`${API_HOST}/api/events/campaign-2020/stories`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}
