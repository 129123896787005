import React, { memo, useEffect } from "react"
import TagManager from "react-gtm-module"
import { useLoadingStore, useModalStore } from "~context/stores"
import styled from "~context/theme"
import { keyframes } from "@emotion/core"

import Footer from "./footer"
import { Backdrop } from "./modals"
import SEO from "./seo"

const ellip1 = keyframes`
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  `

const ellip3 = keyframes`
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  `

const ellip2 = keyframes`
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  `

const Main = styled.main`
  position: relative;
  label: Main;
`

const MotionLoading = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9991;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NormalLoading = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 9992;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EllipsisWrapper = styled.div<{ purple?: boolean }>`
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ purple }) => (purple ? "#6C19BC" : "#fff")};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: ${ellip1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: ${ellip2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: ${ellip2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: ${ellip3} 0.6s infinite;
  }
`

const Ellipsis = memo(({ purple }: { purple?: boolean }) => {
  return (
    <EllipsisWrapper purple={purple}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </EllipsisWrapper>
  )
})

export default memo(({ children }) => {
  const { state: modalState } = useModalStore()
  const { state: loadingState } = useLoadingStore()
  useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-M4GZV3F",
    })
  }, [])

  return (
    <>
      <SEO />
      {/* <Nav /> */}
      <Main>{children}</Main>
      <Footer />

      {modalState.modals.length > 0 ? (
        <Backdrop>{modalState.modals.map(modal => modal)}</Backdrop>
      ) : null}

      {loadingState.show && (
        <NormalLoading>
          <Ellipsis purple />
        </NormalLoading>
      )}

      {loadingState.motionLoading && (
        <MotionLoading>
          <Ellipsis />
        </MotionLoading>
      )}
    </>
  )
})
