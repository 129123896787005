import React, { HTMLAttributes, memo } from "react"
import styled from "../context/theme"

const StyledSectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  text-align: center;
  label: SectionWrapper;
`

const StyledInner = styled.div`
  width: 100%;
  max-width: ${props => props.theme.size.maxWidth}px;
  padding-left: 48px;
  padding-right: 48px;
  label: InnerSection;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`

export const Br = styled.br<{ desktop?: boolean; mobile?: boolean }>`
  display: block;
  ${({ mobile }) =>
    mobile
      ? `
    display: none;
  `
      : `
    display: block;
  `}

  ${({ desktop }) =>
    desktop
      ? `
    display: block;
  `
      : `
    display: none;
  `}

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    ${({ mobile }) =>
      mobile
        ? `
      display: block;
    `
        : `
      display: none;
    `}

  }
`

export const InnerSection: React.FC<HTMLAttributes<
  HTMLElement
>> = memo(({ children, ...props }) => (
  <StyledInner {...props}>{children}</StyledInner>
))

export const SectionWrapper: React.FC<
  HTMLAttributes<HTMLElement> & {
    register?: React.MutableRefObject<any>
  }
> = memo(({ children, register, ...props }) => {
  return (
    <StyledSectionWrapper ref={register} {...props}>
      {children}
    </StyledSectionWrapper>
  )
})
