import React from "react"
import { ThemeProvider } from "emotion-theming"
import {
  ContributeProvider,
  LoadingProvider,
  ModalProvider,
} from "./src/context/stores"
import { theme } from "./src/context/theme"

import "prismjs/themes/prism.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <LoadingProvider>
      <ModalProvider>
        <ContributeProvider>{element}</ContributeProvider>
      </ModalProvider>
    </LoadingProvider>
  </ThemeProvider>
)

export const onInitialClientRender = () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "1121152758328530",
      cookie: true,
      xfbml: true,
      version: "v2.7",
    })

    window.FB.AppEvents.logPageView()
  }
}
