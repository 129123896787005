import React, { memo, useCallback } from "react"
import noop from "lodash/noop"
import ReactDaumPostCode from "react-daum-postcode"
import { Modal } from "../modals"
import { useModalStore } from "~context/stores"
import { css } from "@emotion/core"

export type DaumAddressResult = {
  zonecode: string
  address: string
  addressEnglish: string
  addressType: "R" | "J"
  userSelectedType: "R" | "J"
  userLanguageType: "K" | "E"
  roadAddress: string
  roadAddressEnglish: string
  jibunAddress: string
  jibunAddressEnglish: string
  autoRoadAddress: string
  autoRoadAddressEnglish: string
  autoJibunAddress: string
  autoJibunAddressEnglish: string
  buildingCode: string
  buildingName: string
  apartment: "Y" | "N"
  sido: string
  sigungu: string
  sigunguCode: string
  roadnameCode: string
  bcode: string
  roadname: string
  bname: string
  bname1: string
  bname2: string
  hname: string
  query: string
  postcode: string
  postcode1: string
  postcode2: string
  postcodeSeq: string
  noSelected: "Y" | "N"
}

type DaumPostcodeProps = {
  onComplete: (data: DaumAddressResult) => void
  alwaysShowEngAddr?: boolean
  animation?: boolean
  autoClose?: boolean
  autoMapping?: boolean
  autoResize?: boolean
  defaultQuery?: string
  errorMessage?: React.ReactElement<any>
  height?: number | string
  hideEngBtn?: boolean
  hideMapBtn?: boolean
  maxSuggestItems?: number
  pleaseReadGuide?: number
  pleaseReadGuideTimer?: number
  scriptUrl?: string
  shorthand?: boolean
  showMoreHName?: boolean
  style?: object
  theme?: object
  useSuggest?: boolean
  width?: number | string
  zonecodeOnly?: boolean
  submitMode?: boolean
  focusInput?: boolean
  focusContent?: boolean
  closable?: boolean
}

export default memo(
  ({ closable, onComplete, ...restProps }: DaumPostcodeProps) => {
    const { closeModal } = useModalStore()

    const handleComplete = useCallback(
      (address: DaumAddressResult) => {
        onComplete(address)
        closeModal()
      },
      [closeModal, onComplete]
    )

    return (
      <Modal
        css={css`
          padding: 40px 0;
        `}
        closable={closable}
      >
        <ReactDaumPostCode
          onComplete={handleComplete}
          onSearch={noop}
          {...restProps}
        />
      </Modal>
    )
  }
)
