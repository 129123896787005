import { css, SerializedStyles } from "@emotion/core"
import React, { InputHTMLAttributes } from "react"
import styled from "../../context/theme"

const DefaultButtonStyle = css`
  background: #f7f6f9;
  border: 1px solid #a8a4ac;
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
`

const Self = styled.div`
  text-align: left;

  &:not(:first-of-type) {
    margin-top: 15px;
  }
`

const Button = styled.button`
  ${DefaultButtonStyle}

  margin-left: 10px;
`

const ErrorMessage = styled.div`
  font-size: 14px;
  text-align: right;
  color: #8f00ff;
  text-align: right;
  margin-top: 3px;
`

const Input = styled.input`
  outline: 0;
  border: none;
  width: 100%;
  height: 56px;
  background-color: #f8f7fa;
  border: solid 1px #cbc8d1;
  width: 100%;
  font-size: 18px;
  color: #140a23;
  background-color: #ffffff;
  padding: 14px 0 14px 15px;
  border-radius: 4px;

  &:disabled {
    background-color: #e8e6eb;
    border-radius: 4px;
  }

  &:active,
  &:focus,
  &:invalid {
    outline: 0;
    box-shadow: none;
    border-radius: 4px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #140a23;
  margin-bottom: 8px;
`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  register?: React.Ref<HTMLInputElement>
}

const DfInput: React.FC<
  InputProps & {
    buttonAction?: () => void
    buttonText?: string
    error?: {
      message: string
      type: string
    }
    inputStyle?: SerializedStyles
    labelStyle?: SerializedStyles
    withButton?: boolean
  }
> = ({
  buttonAction,
  buttonText,
  className,
  error,
  inputStyle,
  label,
  labelStyle,
  register,
  withButton,
  ...props
}) => {
  return (
    <Self className={className}>
      {label && <Label css={labelStyle}>{label}</Label>}

      {withButton ? (
        <InputWrapper>
          <Input css={inputStyle} ref={register} {...props} />
          <Button onClick={buttonAction} type="button">
            {buttonText}
          </Button>
        </InputWrapper>
      ) : (
        <Input css={inputStyle} ref={register} {...props} />
      )}
      {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </Self>
  )
}

export default DfInput
