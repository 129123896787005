import React, {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { DeepMap, FieldError, useForm } from "react-hook-form"
import TagManager from "react-gtm-module"
import { yupResolver } from "@hookform/resolvers/yup"
import { Modal } from "~components/modals"
import {
  addressScheme,
  checkAuthScheme,
  increaseAmountScheme,
  joinPeopleExistedScheme,
  joinPeopleExistedWithExistingPaymentMethodScheme,
  joinPeopleNewScheme,
  loginScheme,
} from "~components/form/scheme"
import Input from "~components/form/input"
import PostalCodeModal, {
  DaumAddressResult,
} from "~components/modals/postalCode"
import {
  CardInfo,
  EXISTING_USER_STEP,
  NEW_USER_STEP,
  useContributeStore,
  useLoadingStore,
  useModalStore,
} from "~context/stores"
import styled from "~context/theme"
import * as Facebook from "~utils/fb"
import { css } from "@emotion/core"

const logGTM = {
  purchace: ({ amount, merchantUid }) => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          purchase: {
            actionField: {
              id: merchantUid,
              revenue: amount,
            },
            products: [
              {
                name: "2020 - Year end campaign",
                id: "people",
                price: amount,
                category: "닷페피플",
                variant: "멤버십-정기결제",
                quantity: 1,
              },
            ],
          },
        },
      },
    })
  },
  purchaceDone: (amount = 11000) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "2ndDomainPayment",
        payment: [
          {
            amount,
            category: "2021.dotface.kr",
            step: "STEP_DONE",
          },
        ],
      },
    })
  },
}

type YupErrors = DeepMap<Record<string, any>, FieldError>

const Self = styled(Modal)`
  max-width: 400px;
  height: 100%;
  max-height: 670px;
  overflow-y: scroll;
`

const InnerSection = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`

const CloseButton = styled.span`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 20px;
  height: 20px;
  background-image: url("images/close-black.svg");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
`

const Button = styled.button`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  padding: 16px 0;
  color: #ffffff;
  border-radius: 4px;
  background-color: #6c19bc;
  margin-top: 20px;
`

const CheckEmailFormWrapper = styled.form`
  padding: 30px 0;
`

const AgreeHelpText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #513071;
  text-align: left;
  margin-top: 20px;

  > a {
    text-decoration: underline;
  }
`

const CheckEmailForm: React.FC<HTMLAttributes<HTMLElement>> = memo(() => {
  const { changeEmail, checkEmailForNewUser } = useContributeStore()
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(checkAuthScheme),
  })

  const handleChange = useCallback(
    e => {
      changeEmail(e.target.value)
    },
    [changeEmail]
  )

  const onSubmit = useCallback(async () => {
    try {
      const result = await checkEmailForNewUser()
      if ((result.errors.user as string[]).length > 0) {
        setError("email", {
          type: "duplicated",
          message: result.errors.user[0],
        })
      }
    } catch (e) {
      window.alert(e.message)
    }
  }, [checkEmailForNewUser, setError])

  return (
    <CheckEmailFormWrapper onSubmit={handleSubmit(onSubmit)}>
      <InnerSection>
        <Input
          error={errors.email}
          name="email"
          onChange={handleChange}
          type="email"
          register={register}
          placeholder="이메일 입력"
        />
        <Button disabled={!!errors.email} type="submit">
          동의하고 가입하기
        </Button>
        <AgreeHelpText>
          닷페이스{" "}
          <a
            href="https://www.notion.so/dotface/8f7a311e3d6742dca3cefff2c3dae6f5"
            target="_blank"
            title="이용약관"
          >
            이용약관
          </a>
          과{" "}
          <a
            href="https://www.notion.so/dotface/de70611e21444643aca1a305050330ff"
            target="_blank"
            title="개인정보취급방침"
          >
            개인정보취급방침
          </a>
          을 확인했으며 동의합니다.
        </AgreeHelpText>
      </InnerSection>
    </CheckEmailFormWrapper>
  )
})

const LoginFormWrapper = styled.form`
  padding: 30px 0;
`

const SentEmail = styled.div`
  width: 100%;
  padding-top: 30px;
  text-align: center;
`

const SentEmailTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  color: #6c19bc;
`

const SentEmailDescription = styled.p`
  font-size: 14px;
  color: #4a494d;
  margin-top: 12px;
`

const FacebookHelpText = styled.div`
  width: 100%;
  font-size: 16px;
  text-align: center;
  color: #4a494d;
  margin-top: 20px;
`

const LostPasswordLink = styled.a`
  font-size: 13px;
  text-decoration: underline;
`

const LoginForm: React.FC<
  HTMLAttributes<HTMLElement> & {
    email: string
    newUser?: boolean
  }
> = memo(({ email, newUser }) => {
  const {
    changeEmail,
    changePassword,
    checkEmail,
    loginWithFb,
    loginWithPassword,
    state: contributeState,
  } = useContributeStore()
  const {
    state: { show: loading },
    start: startLoading,
    finish: finishLoading,
  } = useLoadingStore()
  const {
    register: checkAuthRegister,
    handleSubmit: checkAuthHandleSubmit,
    errors: checkAuthErrors,
  } = useForm({
    resolver: yupResolver(checkAuthScheme),
  })

  const {
    register: loginRegister,
    handleSubmit: loginHandleSubmit,
    errors: loginErrors,
    getValues,
  } = useForm({
    resolver: yupResolver(loginScheme),
  })

  const handleChange = useCallback(
    e => {
      changeEmail(e.target.value)
    },
    [changeEmail]
  )

  const handleChangePassword = useCallback(
    e => {
      changePassword(e.target.value)
    },
    [changePassword]
  )

  const handleClickFbLogin = useCallback(async () => {
    startLoading()
    try {
      const result = await Facebook.login()
      const loginResult = await loginWithFb(result.authResponse.accessToken)
      finishLoading()
      if (!loginResult.success) {
        window.alert(Object.values(loginResult.errors).join("\n"))
      }
    } catch (e) {
      finishLoading()
      window.alert(
        "페이스북 로그인에 문제가 발생했습니다. 새로고침 후 다시 한 번 시도해주세요. 3번 이상 반복되면 저희에게 알려주세요!"
      )
    }
  }, [startLoading, finishLoading])

  const checkAuthOnSubmit = useCallback(
    async (data: { email }) => {
      startLoading()
      try {
        await checkEmail(data.email)
        finishLoading()
      } catch (e) {
        finishLoading()
        window.alert(e.message)
      }
    },
    [checkEmail, startLoading, finishLoading]
  )

  const loginOnSubmit = useCallback(async () => {
    startLoading()
    try {
      const result = await loginWithPassword()
      finishLoading()
      if (!result.success) {
        window.alert(`${Object.values(result.errors).join("\n")}`)
      }
    } catch (e) {
      finishLoading()
      window.alert(e.message)
    }
  }, [loginWithPassword])

  let handleSubmit = checkAuthHandleSubmit
  let register = checkAuthRegister
  let errors = checkAuthErrors
  let onSubmit = checkAuthOnSubmit
  if (
    contributeState.existingUserStep === EXISTING_USER_STEP.NEED_OTHER_METHOD
  ) {
    register = loginRegister
    handleSubmit = loginHandleSubmit
    errors = loginErrors
    onSubmit = loginOnSubmit
  }

  const password = getValues("password")

  return (
    <LoginFormWrapper onSubmit={handleSubmit(onSubmit)}>
      <InnerSection>
        <Input
          error={errors.email}
          name="email"
          onChange={handleChange}
          type="email"
          register={register}
          placeholder="이메일 입력"
        />

        {!newUser &&
          contributeState.existingUserStep !== EXISTING_USER_STEP.INITIAL &&
          contributeState.existingUserStep !==
            EXISTING_USER_STEP.SENT_EMAIL && (
            <>
              <Input
                autoFocus
                css={css`
                  margin-top: 15px;
                `}
                name="password"
                onChange={handleChangePassword}
                type="password"
                register={register}
                placeholder="비밀번호 입력"
              />

              <Button disabled={loading} type="submit">
                로그인
              </Button>
              <LostPasswordLink
                href="https://dotface.kr/user/restore_password"
                target="_blank"
                title="비밀번호 찾기"
              >
                비밀번호 찾기
              </LostPasswordLink>

              <FacebookHelpText>페이스북으로 가입하셨나요?</FacebookHelpText>

              <Button
                css={css`
                  background-color: #456ea7;
                  margin-top: 18px;
                `}
                onClick={handleClickFbLogin}
                type="button"
              >
                페이스북으로 로그인하기
              </Button>
            </>
          )}

        {(contributeState.existingUserStep === EXISTING_USER_STEP.INITIAL ||
          contributeState.existingUserStep ===
            EXISTING_USER_STEP.SENT_EMAIL) && (
          <Button disabled={loading || !email || !!errors.email} type="submit">
            다음으로
          </Button>
        )}

        {contributeState.existingUserStep === EXISTING_USER_STEP.SENT_EMAIL && (
          <SentEmail>
            <SentEmailTitle>
              로그인을 위한
              <br />
              인증 메일을 보내드렸어요
            </SentEmailTitle>
            <SentEmailDescription>
              입력하신 이메일의 메일함을 확인해주세요!
            </SentEmailDescription>
          </SentEmail>
        )}
      </InnerSection>
    </LoginFormWrapper>
  )
})

const NewPaymentMethodFormWrapper = styled.div`
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
`

const NewPaymentMethodForm: React.FC<
  HTMLAttributes<HTMLElement> & {
    errors: YupErrors
    register: any
  }
> = memo(({ errors, register }) => {
  const { changeCard, state: contributeState } = useContributeStore()

  const handleChange = useCallback(
    e => {
      changeCard({
        [e.target.name]: e.target.value,
      } as CardInfo)
    },
    [changeCard]
  )

  return (
    <NewPaymentMethodFormWrapper>
      <Input
        error={errors.card_number}
        label="카드 번호"
        name="card_number"
        onChange={handleChange}
        register={register}
        maxLength={16}
        minLength={14}
        placeholder="카드번호 16자리"
        inputMode="numeric"
      />
      <Input
        css={css`
          width: 50%;
        `}
        error={errors.card_expiry}
        label="유효기간"
        name="card_expiry"
        onChange={handleChange}
        register={register}
        maxLength={4}
        minLength={4}
        placeholder="MMYY"
        inputMode="numeric"
      />
      <Input
        error={errors.card_password}
        label="비밀번호 앞 두자리"
        name="card_password"
        onChange={handleChange}
        register={register}
        type="password"
        maxLength={2}
        minLength={2}
        placeholder="비밀번호 앞 2자리"
        inputMode="numeric"
      />
      <Input
        error={errors.card_birthday}
        label="주민번호 앞 6자리"
        name="card_birthday"
        onChange={handleChange}
        register={register}
        maxLength={6}
        minLength={6}
        placeholder=""
        inputMode="numeric"
      />
    </NewPaymentMethodFormWrapper>
  )
})

const EmailText = styled.div``

const JoinPeopleForm = styled.form``

const PaymentMethodSelectSelf = styled.div``

const PaymentMethodUsageTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #cbc8d1;
`

const PaymentMethodUsageLabel = styled.label<{ active: boolean }>`
  font-size: 16px;
  text-align: center;
  color: #7b7880;
  width: 100%;
  padding: 13px 0;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    color: #6c19bc;
    font-weight: bold;
    border-bottom: 4px solid #8f00ff;
`}
`

const ExistingPaymentMethodWrapper = styled.div`
  padding: 20px 20px 22px;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-size: 16px;
  text-align: left;
  color: #4a494d;
  margin-top: 20px;
`

const PaymentMethodSelect: React.FC<
  HTMLAttributes<HTMLElement> & {
    errors: YupErrors
    onChangeUseExsitingPaymentMethod: (use: boolean) => void
    register: any
    useExistingPaymentMethod: boolean
  }
> = memo(
  ({
    errors,
    onChangeUseExsitingPaymentMethod,
    register,
    useExistingPaymentMethod,
  }) => {
    const { state: contributeState } = useContributeStore()

    const paymentMethod = contributeState.paymentMethod

    const handleChange = useCallback(
      e => {
        if (e.target.value === "existing" && e.target.checked) {
          onChangeUseExsitingPaymentMethod(true)
        }

        if (e.target.value === "new" && e.target.checked) {
          onChangeUseExsitingPaymentMethod(false)
        }
      },
      [onChangeUseExsitingPaymentMethod]
    )

    return (
      <PaymentMethodSelectSelf>
        <PaymentMethodUsageTabs>
          <Input
            css={css`
              display: none;
            `}
            id="existing"
            checked={useExistingPaymentMethod === true}
            name="pm"
            type="radio"
            value="existing"
            onChange={handleChange}
          />
          <PaymentMethodUsageLabel
            active={useExistingPaymentMethod}
            htmlFor="existing"
          >
            기존 결제수단
          </PaymentMethodUsageLabel>
          <Input
            checked={useExistingPaymentMethod === false}
            css={css`
              display: none;
            `}
            id="new"
            name="pm"
            type="radio"
            value="new"
            onChange={handleChange}
          />
          <PaymentMethodUsageLabel
            active={!useExistingPaymentMethod}
            htmlFor="new"
          >
            신규 결제수단
          </PaymentMethodUsageLabel>
        </PaymentMethodUsageTabs>

        {useExistingPaymentMethod ? (
          <ExistingPaymentMethodWrapper>
            카드명: {paymentMethod.name}
            <br />
            카드번호: {paymentMethod.number}
          </ExistingPaymentMethodWrapper>
        ) : (
          <NewPaymentMethodForm errors={errors} register={register} />
        )}
      </PaymentMethodSelectSelf>
    )
  }
)

const AmountSelectSelf = styled.div`
  background-color: #8f00ff;
  color: white;
  padding: 30px 30px 40px;
  margin-top: 30px;
`

const AmountSelectTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  text-align: left;
`

const AmountDescription = styled.div`
  font-size: 16px;
  margin-top: 5px;
  text-align: left;
`

const Amounts = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const AmountOption = styled.input`
  display: none;
`
const AmountOptionLabel = styled.label<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 95px;
  width: 97px;
  height: 54px;
  border-radius: 4px;
  border: solid 1px #e9c7ff;
  color: #e9c7ff;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    color: #8f00ff;
    background-color: #ffffff;
  `}
`

const CustomAmountButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 54px;
  border-radius: 4px;
  border: solid 1px #e9c7ff;
  color: #e9c7ff;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
`

const AmountCustomInput = styled.input`
  position: relative;
  width: 100%;
  padding: 14px 15px 15px;
  border-radius: 4px;
  border: solid 1px #e9c7ff;
  margin-top: 10px;
  background-color: transparent;
  color: white;

  &:active,
  &:focus,
  &:invalid {
    outline: 0;
    box-shadow: none;
  }

  &:active,
  &:focus {
    color: #140a23;
    border-radius: 4px;
    background-color: #f8f7fa;
  }

  &::after {
    content: " ";
    font-size: 18px;
    color: #e9c7ff;
    position: absolute;
    top: 0;
    right: 0;
  }
`

const AmountCustomInputHelpText = styled.div`
  text-align: right;
  font-size: 12px;
`

const GoodsList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
`

const GoodsItem = styled.li`
  width: 100%;
  text-align: left;
  font-weight: bold;

  &:not(:first-of-type) {
    margin-left: 5px;
  }
`

const GoodsTitle = styled.div<{ deactivated?: boolean }>`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;

  ${({ deactivated }) =>
    deactivated &&
    `
    opacity: 0.3;
  `}
`

const GoodsImage = styled.div<{ deactivated?: boolean; src: string }>`
  position: relative;
  width: 100%;
  height: 129px;
  margin-top: 10px;
  border-radius: 10px;
  background-image: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-size: cover;

  ${({ deactivated }) =>
    deactivated &&
    css`
      background-color: transparent;
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: 0.3;
        border-radius: 10px;
      }
    `}
`

const GoodsImageText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const GoodsDescription = styled.div<{ deactivated?: boolean }>`
  font-size: 16px;
  color: #ffffff;
  margin-top: 10px;

  ${({ deactivated }) =>
    deactivated &&
    `
    opacity: 0.3;
  `}
`

const Goods = memo(({ amount }: { amount: number }) => {
  const {
    state: {
      application: { notGoods },
    },
  } = useContributeStore()
  return (
    <GoodsList>
      <GoodsItem key="default">
        {/* <GoodsTitle deactivated={notGoods}></GoodsTitle> */}
        <GoodsImage deactivated={notGoods} src="images/badge-apply@2x.jpg" />
        <GoodsDescription deactivated={notGoods}>뱃지, 스티커</GoodsDescription>
      </GoodsItem>
      <GoodsItem key="extended">
        {/* <GoodsTitle deactivated={notGoods || amount < 25000}></GoodsTitle> */}
        <GoodsImage
          deactivated={notGoods || amount < 25000}
          src="images/camera-apply@2x.jpg"
        >
          {!notGoods && amount < 25000 && (
            <GoodsImageText>
              2.5만원 이상이면
              <br />
              받을 수 있어요
            </GoodsImageText>
          )}
        </GoodsImage>
        <GoodsDescription deactivated={notGoods || amount < 25000}>
          닷페 필름카메라
        </GoodsDescription>
      </GoodsItem>
    </GoodsList>
  )
})

const AmountSelect = memo(
  ({
    amount = 11000,
    errors,
    register,
    setValue,
  }: {
    amount: number
    errors: YupErrors
    setValue: any
    register: any
  }) => {
    const { changeApplication } = useContributeStore()
    const [focusedCustomInput, setFocused] = useState(false)
    const [customAmount, setCustomAmount] = useState(0)

    useEffect(() => {
      if (amount !== 11000 && amount !== 25000 && amount !== 35000) {
        setFocused(true)
        setCustomAmount(amount)
      }
    }, [])

    const handleClick = useCallback(
      e => {
        setFocused(false)
        setValue("amount", +e.target.value)
        changeApplication({
          amount: +e.target.value,
        })
      },
      [changeApplication, setFocused, setCustomAmount]
    )

    const handleChangeCustomAmount = useCallback(
      e => {
        changeApplication({
          amount: +e.target.value,
        })

        setCustomAmount(+e.target.value)
      },
      [changeApplication, setCustomAmount]
    )

    const handleFocusCustomInput = useCallback(() => {
      setFocused(true)
      if (!isNaN(amount)) {
        changeApplication({
          amount: customAmount,
        })
      }
    }, [changeApplication, customAmount, setFocused])

    const handleClickCustomAmountButton = useCallback(() => {
      setFocused(true)
      setCustomAmount(amount)
    }, [setFocused, setCustomAmount])

    return (
      <AmountSelectSelf>
        <AmountSelectTitle>월 정기 후원 금액을 선택해주세요</AmountSelectTitle>
        <AmountDescription>
          금액에 따라 굿즈가 달라져요!(단위, 원)
          <br />
          아래 보이는 굿즈를 받을 수 있어요.
        </AmountDescription>
        <Amounts>
          <AmountOption
            id="amount-11000"
            name="amount"
            onClick={handleClick}
            type="radio"
            value={11000}
            checked={!focusedCustomInput && amount === 11000}
            ref={register}
          />
          <AmountOptionLabel
            active={!focusedCustomInput && amount === 11000}
            tabIndex={0}
            htmlFor="amount-11000"
          >
            11,000
          </AmountOptionLabel>
          <AmountOption
            id="amount-25000"
            name="amount"
            onClick={handleClick}
            type="radio"
            value={25000}
            checked={!focusedCustomInput && amount === 25000}
            ref={register}
          />
          <AmountOptionLabel
            active={!focusedCustomInput && amount === 25000}
            htmlFor="amount-25000"
          >
            25,000
          </AmountOptionLabel>
          <AmountOption
            id="amount-35000"
            name="amount"
            onClick={handleClick}
            type="radio"
            value={35000}
            checked={!focusedCustomInput && amount === 35000}
            ref={register}
          />
          <AmountOptionLabel
            active={!focusedCustomInput && amount === 35000}
            htmlFor="amount-35000"
          >
            35,000
          </AmountOptionLabel>
          <CustomAmountButton onClick={handleClickCustomAmountButton}>
            기타
          </CustomAmountButton>
        </Amounts>
        {focusedCustomInput && (
          <>
            <AmountCustomInput
              autoFocus
              ref={register}
              onFocus={handleFocusCustomInput}
              name="amount"
              type="number"
              onChange={handleChangeCustomAmount}
              placeholder="직접 입력하기"
              value={customAmount}
              inputMode="numeric"
            />
            <AmountCustomInputHelpText>
              최소 11,000원 이상부터 가능합니다.
            </AmountCustomInputHelpText>
          </>
        )}
        <Goods amount={amount} />
      </AmountSelectSelf>
    )
  }
)

const JoinPeopleWrapper = styled.div`
  width: 100%;
`

const DefaultTitle = styled.h2`
  color: #221c32;
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-top: 31px;
`

const PaymentMethodSection = styled.section`
  background-color: #f8f7fa;
  padding: 20px 0;
`

const JoinButtonWrapper = styled.section`
  background-color: #f8f7fa;
`

const NotGoodsSelection = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #222026;
  padding: 21px 0 19px;
`

const NotGoodsInput = styled.input`
  border-radius: 4px;
  border: solid 1px #cbc8d1;
`

const NotGoodsLabel = styled.label`
  font-size: 16px;
  color: white;
  margin-left: 7px;
  cursor: pointer;
`

const JoinPeople: React.FC<
  HTMLAttributes<HTMLElement> & {
    newUser?: boolean
  }
> = memo(({ newUser = false }) => {
  const {
    state: { show: loading },
    start: startLoading,
    finish: finishLoading,
  } = useLoadingStore()
  const {
    apply,
    applyExisted,
    changeApplication,
    state: contributeState,
  } = useContributeStore()
  const [useExistingPaymentMethod, setUseExistingPaymentMethod] = useState(
    false
  )
  const { application } = contributeState

  let scheme = joinPeopleExistedScheme
  const paymentMethod = contributeState.paymentMethod
  if (paymentMethod) {
    scheme = joinPeopleExistedWithExistingPaymentMethodScheme
  }
  if (newUser) {
    scheme = joinPeopleNewScheme
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(scheme),
    mode: "onBlur",
  })

  useEffect(() => {
    if (paymentMethod) {
      setUseExistingPaymentMethod(true)
    }

    if (application.phone) {
      setValue("phone", application.phone)
    }

    if (application.nickname) {
      setValue("nickname", application.nickname)
    }
  }, [])

  const handleChange = useCallback(
    e => {
      const key = e.target.name
      const value =
        e.target.name === "notGoods" ? e.target.checked : e.target.value
      changeApplication({
        [key]: value,
      })
    },
    [changeApplication]
  )

  const handleChangeUseExistingPaymentMethod = useCallback(
    (use: boolean) => {
      setUseExistingPaymentMethod(use)
    },
    [setUseExistingPaymentMethod]
  )

  const onSubmit = useCallback(
    async e => {
      startLoading()
      try {
        let result: {
          success?: boolean
          data?: {
            merchant_uid: string
          }
          errors?: {
            [key: string]: string
          }
          message?: string
        } = {}
        if (newUser) {
          result = await apply()
        } else {
          result = await applyExisted({ useExistingPaymentMethod })
        }

        finishLoading()

        if (!result.success) {
          if (result.message) {
            window.alert(result.message)
          } else {
            window.alert(Object.values(result.errors).join("\n"))
          }
        } else {
          logGTM.purchace({
            amount: application.amount,
            merchantUid: result.data.merchant_uid,
          })
          logGTM.purchaceDone(application.amount)
        }
      } catch (e) {
        finishLoading()
        window.alert(e.message)
      }
    },
    [application.amount, apply, applyExisted, newUser]
  )

  return (
    <JoinPeopleWrapper>
      <DefaultTitle>
        <InnerSection>
          닷페이스 후원 신청하고
          <br />
          닷페피플이 되세요!
        </InnerSection>
      </DefaultTitle>
      <JoinPeopleForm onSubmit={handleSubmit(onSubmit)}>
        <InnerSection>
          <Input
            css={css`
              margin-top: 40px;
            `}
            disabled
            label="이메일"
            value={application.email}
          />
          <Input
            css={css`
              margin-top: 16px;
            `}
            label="닉네임"
            error={errors.nickname}
            name="nickname"
            onChange={handleChange}
            placeholder="닉네임 입력(10자리 이내)"
            register={register}
          />
          <Input
            css={css`
              margin-top: 16px;
            `}
            error={errors.phone}
            label="휴대폰 번호"
            name="phone"
            onChange={handleChange}
            placeholder="-를 제외한 숫자만 입력"
            register={register}
            inputMode="numeric"
          />
        </InnerSection>

        <AmountSelect
          amount={contributeState.application.amount}
          errors={errors}
          register={register}
          setValue={setValue}
        />

        <NotGoodsSelection>
          <InnerSection>
            <NotGoodsInput
              id="notGoods"
              name="notGoods"
              onChange={handleChange}
              type="checkbox"
              ref={register}
              checked={contributeState.application.notGoods}
            />
            <NotGoodsLabel htmlFor="notGoods">
              굿즈 미수령(굿즈를 받지 않을래요)
            </NotGoodsLabel>
          </InnerSection>
        </NotGoodsSelection>

        {!contributeState.application.notGoods && (
          <AddressFormWrapper>
            <InnerSection>
              <DefaultTitle
                css={css`
                  font-size: 18px;
                  line-height: 26px;
                `}
              >
                굿즈 발송을 위해
                <br />
                주소를 정확히 입력해주세요
              </DefaultTitle>
              <Address newUser={newUser} />
            </InnerSection>
          </AddressFormWrapper>
        )}

        <PaymentMethodSection>
          <InnerSection>
            {paymentMethod && paymentMethod.id ? (
              <PaymentMethodSelect
                errors={errors}
                onChangeUseExsitingPaymentMethod={
                  handleChangeUseExistingPaymentMethod
                }
                register={register}
                useExistingPaymentMethod={useExistingPaymentMethod}
              />
            ) : (
              <NewPaymentMethodForm errors={errors} register={register} />
            )}
          </InnerSection>
        </PaymentMethodSection>

        <JoinButtonWrapper>
          <InnerSection>
            <Button disabled={loading} type="submit">
              닷페피플 가입하기
            </Button>
          </InnerSection>
        </JoinButtonWrapper>
      </JoinPeopleForm>
    </JoinPeopleWrapper>
  )
})

const AddressSelf = styled.div``

const AddressFormWrapper = styled.div``

const AddressForm = styled.form`
  margin-top: 30px;
`

const PostalCodeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
`

const Address = memo(({ newUser }: { newUser: boolean }) => {
  const {
    changeAddress,
    state: contributeState,
    updateAddress,
  } = useContributeStore()
  const { address } = contributeState
  const { openModal } = useModalStore()
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(addressScheme),
    mode: "onBlur",
  })

  useEffect(() => {
    if (address.name) {
      setValue("name", address.name)
    }
    if (address.address_common) {
      setValue("address_common", address.address_common)
    }
    if (
      typeof address.address_common === "string" &&
      address.address_append !== null &&
      address.address_append !== "null"
    ) {
      setValue("address_append", address.address_append)
    } else {
      setValue("address_append", "")
    }
    if (address.postal_code) {
      setValue("postal_code", address.postal_code)
    }
  }, [])

  const handleSearch = useCallback(
    (_address: DaumAddressResult) => {
      changeAddress({
        address_common: _address.roadAddress,
        postal_code: _address.zonecode,
      })
      setValue("address_common", _address.roadAddress)
      setValue("postal_code", _address.zonecode)
    },
    [changeAddress, setValue]
  )

  const handleClickSearch = useCallback(() => {
    openModal(<PostalCodeModal closable onComplete={handleSearch} />)
  }, [])

  const handleChange = useCallback(e => {
    changeAddress({
      [e.target.name]: e.target.value,
    })
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      const result = await updateAddress(newUser)
      if (!result.success) {
        window.alert(Object.values(result.errors).join("\n"))
      }
    } catch (e) {
      window.alert(e.message)
    }
  }, [])

  return (
    <AddressSelf>
      <AddressForm onSubmit={handleSubmit(onSubmit)}>
        <Input
          error={errors.name}
          label="수령인"
          name="name"
          onChange={handleChange}
          placeholder="이름 입력"
          register={register}
          value={address.name}
        />
        <PostalCodeRow>
          <Input
            css={css`
              flex-grow: 2;
              margin-right: 10px;
            `}
            error={errors.postal_code}
            label="주소"
            placeholder="우편번호"
            disabled
            name="postal_code"
            register={register}
          />
          <Button
            css={css`
              font-size: 16px;
              font-weight: bold;
              line-height: 21px;
              flex-basis: 90px;
              height: 54px;
              border-radius: 4px;
              color: #6c19bc;
              border: solid 1px rgba(108, 25, 188, 0.6);
              background-color: #ffffff;
            `}
            onClick={handleClickSearch}
            type="button"
          >
            검색
          </Button>
        </PostalCodeRow>
        <Input
          placeholder="기본주소"
          disabled
          error={errors.address_common}
          name="address_common"
          register={register}
        />
        <Input
          error={errors.address_append}
          name="address_append"
          onChange={handleChange}
          placeholder="상세주소"
          register={register}
        />
      </AddressForm>
    </AddressSelf>
  )
})

const WantIncreaseAmountSelf = styled.div``

const WantIncreaseAmount = memo(() => {
  const {
    state: { show: loading },
    start: startLoading,
    finish: finishLoading,
  } = useLoadingStore()
  const initAmountRef = useRef(0)
  const { increaseAmount, state: contributeState } = useContributeStore()
  const { application } = contributeState
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(increaseAmountScheme),
    mode: "onBlur",
  })

  useEffect(() => {
    initAmountRef.current = contributeState.currentMembership.amount
  }, [])

  const onSubmit = useCallback(async () => {
    startLoading()
    try {
      const result = await increaseAmount()
      finishLoading()
      if (!result.success) {
        window.alert(Object.values(result.errors).join("\n"))
      }
    } catch (e) {
      finishLoading()
      window.alert(e.message)
    }
  }, [increaseAmount, startLoading, finishLoading])

  let buttonText = "신청하기"
  if (
    initAmountRef.current &&
    initAmountRef.current < 25000 &&
    application.amount >= 25000
  ) {
    buttonText = "증액하고 신청하기"
  }

  return (
    <WantIncreaseAmountSelf>
      <DefaultTitle>
        <InnerSection>
          2020 연말 캠페인
          <br />
          굿즈를 신청해주세요!
        </InnerSection>
      </DefaultTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AmountSelect
          amount={application.amount}
          errors={errors}
          register={register}
          setValue={setValue}
        />

        <InnerSection>
          <Address newUser={false} />
        </InnerSection>

        <InnerSection>
          <Button disabled={loading} type="submit">
            {buttonText}
          </Button>
        </InnerSection>
      </form>
    </WantIncreaseAmountSelf>
  )
})

const AlreadyAppliedSelf = styled.div``

const AlreadyApplied = memo(() => {
  return <AlreadyAppliedSelf>이미 신청했어!</AlreadyAppliedSelf>
})

const CongraturationSelf = styled.div``

const CongraturationHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 177px;
  padding: 30px 0 50px;
  background-image: url("images/bg-congraturation.jpg");
  background-size: cover;
  background-repeat: no-repeat;
`

const CongraturationHeaderSubTitle = styled.div`
  opacity: 0.5;
  color: white;
`
const CongraturationHeaderTitle = styled.div`
  color: white;
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: -0.5px;
  margin-top: 15px;
`

const WelcomeMessage = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  color: #222026;
`

const Nickname = styled.span`
  color: #8f00ff;
`

const WelcomeDescription = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #140a23;
  margin-top: 15px;
`

const AlreadyPeopleGuide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const AlreadyPeopleGuideList = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #7b7880;
`

const AlreadyPeopleGuideText = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #140a23;
  margin-top: 15px;
`

const AlreadyPeopleGuideEmail = styled.a`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #6c19bc;
  margin-top: 15px;
`

const Congraturation: React.FC<
  HTMLAttributes<HTMLElement> & {
    alreadyPeople?: boolean
    newUser?: boolean
  }
> = memo(({ newUser = false, alreadyPeople = false }) => {
  const { state: contributeState } = useContributeStore()
  const { closeModal } = useModalStore()
  const handleClick = useCallback(() => {
    window.location.href = "/#your-story"
    closeModal()
  }, [closeModal])
  return (
    <CongraturationSelf>
      {alreadyPeople ? (
        <CongraturationHeader>
          <CongraturationHeaderSubTitle>Thanks!</CongraturationHeaderSubTitle>
          <CongraturationHeaderTitle>
            연말이벤트에
            <br />
            참여해주셔서 감사합니다!
          </CongraturationHeaderTitle>
        </CongraturationHeader>
      ) : (
        <CongraturationHeader>
          <CongraturationHeaderSubTitle>Welcome!</CongraturationHeaderSubTitle>
          <CongraturationHeaderTitle>
            닷페이스를 후원하는
            <br />
            닷페피플이 되셨습니다!
          </CongraturationHeaderTitle>
        </CongraturationHeader>
      )}

      <InnerSection
        css={css`
          padding-top: 20px;
          padding-bottom: 20px;
        `}
      >
        <WelcomeMessage>
          <Nickname>{contributeState.application.nickname}</Nickname>님,
          <br />
          {alreadyPeople
            ? "닷페피플 가입과 연말 캠페인 굿즈 신청을 완료하셨어요!"
            : "닷페피플이 되신걸 환영해요!"}
        </WelcomeMessage>

        <WelcomeDescription>
          2021년, 어떤 변화가 일어나길 바라나요?
          <br />
          닷페가 전하길 바라는 변화에 대해 이야기를 남겨주시고 공유해주세요!
          닷페피플의 생각을 듣고 싶어요. 우리가 만들어나갈 이야기에 큰 도움이
          되거든요.
        </WelcomeDescription>
        <Button onClick={handleClick} type="button">
          내가 바라는 변화 알려주기
        </Button>

        {alreadyPeople && (
          <AlreadyPeopleGuide>
            <AlreadyPeopleGuideList>
              “굿즈를 변경하거나, 증액을 하고 싶어요!”
              <br />
              “다른 문의사항이 있어요”
            </AlreadyPeopleGuideList>
            <AlreadyPeopleGuideText>
              더 문의할 것이 있다면 아래 주소로 메일주세요.
            </AlreadyPeopleGuideText>
            <AlreadyPeopleGuideEmail href="mailto:hey@dotface.kr">
              hey@dotface.kr
            </AlreadyPeopleGuideEmail>
          </AlreadyPeopleGuide>
        )}
      </InnerSection>
    </CongraturationSelf>
  )
})

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`

const NewUserTab = memo(() => {
  const { state: contributeState } = useContributeStore()
  let content = <CheckEmailForm />

  if (contributeState.newUserStep === NEW_USER_STEP.CHECKED_EMAIL) {
    content = <JoinPeople newUser />
  }

  if (contributeState.newUserStep === NEW_USER_STEP.NEED_ADDRESS) {
    content = <Address newUser />
  }

  if (contributeState.newUserStep === NEW_USER_STEP.CONGRATURATION) {
    content = <Congraturation newUser />
  }
  return <TabContent>{content}</TabContent>
})

const ExistingUserTab = memo(() => {
  const { state: contributeState } = useContributeStore()
  let content = <LoginForm email={contributeState.application.email} />

  if (contributeState.existingUserStep === EXISTING_USER_STEP.LOGGED_IN) {
    content = <JoinPeople />
  }
  if (contributeState.existingUserStep === EXISTING_USER_STEP.ALREADY_PEOPLE) {
    content = <WantIncreaseAmount />
  }

  if (contributeState.existingUserStep === EXISTING_USER_STEP.ALREADY_APPLIED) {
    content = <Congraturation alreadyPeople />
  }

  if (contributeState.existingUserStep === EXISTING_USER_STEP.CONGRATURATION) {
    content = <Congraturation alreadyPeople />
  }

  if (
    contributeState.existingUserStep ===
    EXISTING_USER_STEP.CONGRATURATION_FOR_PEOPLE
  ) {
    content = <Congraturation alreadyPeople />
  }

  return <TabContent>{content}</TabContent>
})

const HeaderSelf = styled.header`
  position: relative;
  height: 47px;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding: 13px 0 14px;
  border-bottom: 1px solid #e8e6eb;
`

const BackwardButton = styled.span`
  position: absolute;
  top: 14px;
  left: 14px;
  width: 20px;
  height: 20px;
  background-image: url("images/backward-black.svg");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
`

const Header: React.FC<
  HTMLAttributes<HTMLElement> & {
    tab: number
  }
> = memo(({ tab = 1 }) => {
  const {
    backward,
    state: { newUserStep, existingUserStep },
  } = useContributeStore()
  const { closeModal } = useModalStore()
  const handleClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleClickBackward = useCallback(() => {
    backward(tab === 1)
  }, [backward, tab])

  let title = "인증하기"
  if (tab === 1) {
    switch (newUserStep) {
      case NEW_USER_STEP.CONGRATURATION:
        title = "완료"
        break
      case NEW_USER_STEP.CHECKED_EMAIL:
        title = "후원하기"
        break
      default:
        break
    }
  } else {
    switch (existingUserStep) {
      case EXISTING_USER_STEP.LOGGED_IN:
        title = "후원하기"
        break
      case EXISTING_USER_STEP.ALREADY_PEOPLE:
        title = "굿즈 신청하기"
        break
      case EXISTING_USER_STEP.ALREADY_APPLIED:
      case EXISTING_USER_STEP.CONGRATURATION:
      case EXISTING_USER_STEP.CONGRATURATION_FOR_PEOPLE:
        title = "완료"
        break
      default:
        break
    }
  }

  return (
    <HeaderSelf>
      {title}
      <CloseButton onClick={handleClose} />
    </HeaderSelf>
  )
})

const Body = styled.div``

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #cbc8d1;
`

const Tab = styled.div<{ active?: boolean }>`
  width: 100%;
  color: #7b7880;
  font-size: 16px;
  text-align: center;
  padding: 13px 0;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
      color: #6c19bc;
      font-weight: bold;
      border-bottom: 4px solid #8f00ff;
  `}
`

const PleaseIncrease = styled.div`
  width: 100%;
  padding: 10px 30px 12px;
  background-color: #140a23;
  color: white;
  font-size: 16px;
  line-height: normal;
  text-align: left;
`

const ContributeModal: React.FC<
  HTMLAttributes<HTMLElement> & {
    isOld?: boolean
  }
> = memo(({ isOld }) => {
  const { state: contributeState } = useContributeStore()
  const [selectedTab, setSelectedTab] = useState(isOld ? 2 : 1)

  const handleClickTab = useCallback(
    e => {
      const tab = e.target.getAttribute("data-tab")
      switch (tab) {
        case "tab-2":
          setSelectedTab(2)
          break
        default:
          setSelectedTab(1)
          break
      }
    },
    [setSelectedTab]
  )

  return (
    <Self>
      <Header tab={selectedTab} />

      <Body>
        <InnerSection>
          {((selectedTab === 1 &&
            contributeState.newUserStep === NEW_USER_STEP.INITIAL) ||
            (selectedTab === 2 &&
              (contributeState.existingUserStep ===
                EXISTING_USER_STEP.INITIAL ||
                contributeState.existingUserStep ===
                  EXISTING_USER_STEP.SENT_EMAIL ||
                contributeState.existingUserStep ===
                  EXISTING_USER_STEP.NEED_OTHER_METHOD))) && (
            <Tabs>
              <Tab
                active={selectedTab === 1}
                data-tab="tab-1"
                key="tab-1"
                onClick={handleClickTab}
              >
                신규회원
              </Tab>
              <Tab
                active={selectedTab === 2}
                data-tab="tab-2"
                key="tab-2"
                onClick={handleClickTab}
              >
                기존 사이트 회원
              </Tab>
            </Tabs>
          )}
        </InnerSection>

        {/* {selectedTab === 2 &&
          contributeState.existingUserStep ===
            EXISTING_USER_STEP.ALREADY_PEOPLE && (
            <PleaseIncrease>
              25,000원 이상으로 증액해달라는
              <br />
              설득메시지 영역입니다.
            </PleaseIncrease>
          )} */}

        {selectedTab === 1 && <NewUserTab />}
        {selectedTab === 2 && <ExistingUserTab />}
      </Body>
    </Self>
  )
})

export default ContributeModal
