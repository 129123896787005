import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useReducer,
} from "react"
import { useContext } from "react"

enum ACTION_TYPES {
  START = "START",
  FINISH = "FINISH",
  FINISH_MOTION = "FINISH_MOTION",
}

type Action =
  | { type: ACTION_TYPES.START }
  | { type: ACTION_TYPES.FINISH }
  | { type: ACTION_TYPES.FINISH_MOTION }

interface State {
  motionLoading: boolean
  show: boolean
}

const INITIAL_STATE: State = {
  motionLoading: true,
  show: false,
}

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.START:
      return {
        ...prevState,
        show: true,
      }
    case ACTION_TYPES.FINISH:
      return {
        ...prevState,
        show: false,
      }
    case ACTION_TYPES.FINISH_MOTION:
      return {
        ...prevState,
        motionLoading: false,
      }
    default:
      return INITIAL_STATE
  }
}

export const Context = createContext<{
  state: typeof INITIAL_STATE
  dispatch: (action: Action) => void
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
})

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  )
}

export const useLoadingStore = () => {
  const { state, dispatch } = useContext(Context)

  const start = useCallback(() => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden"
    dispatch({
      type: ACTION_TYPES.START,
    })
  }, [dispatch])

  const finish = useCallback(() => {
    document.getElementsByTagName("html")[0].style.overflow = "inherit"
    dispatch({
      type: ACTION_TYPES.FINISH,
    })
  }, [dispatch])

  const finishMotion = useCallback(() => {
    document.getElementsByTagName("html")[0].style.overflow = "inherit"
    dispatch({
      type: ACTION_TYPES.FINISH_MOTION,
    })
  }, [dispatch])

  return {
    state,
    start,
    finish,
    finishMotion,
  }
}
