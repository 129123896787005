import React, { memo, useCallback, useEffect, useState } from "react"
import {
  Br,
  InnerSection as InnerSectionOrig,
  SectionWrapper as SectionWrapperOrig,
} from "~components/common"
import ShareEventModal from "~components//modals/shareEvent"
import styled from "~context/theme"
import { useLoadingStore, useModalStore } from "~context/stores"
import * as api from "~utils/api"

const Self = styled(SectionWrapperOrig)`
  position: relative;
  background-color: black;
  padding-top: 80px;
  padding-bottom: 534px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    padding-bottom: 300px;
  }
`

const InnerSection = styled(InnerSectionOrig)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DimLayer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40vh;
  background-image: linear-gradient(
    to bottom,
    rgba(130, 63, 194, 0) 6%,
    rgba(130, 63, 194, 0.6) 48%,
    rgba(130, 63, 194, 0.94) 73%,
    #823fc2 85%
  );
  z-index: 1001;
`

const SubTitle = styled.h5`
  color: #e9c7ff;
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: -0.5px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
  }
`

const Title = styled.h2`
  color: white;
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: -1px;
  margin-top: 14px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 26px;
    line-height: 34px;
    margin-top: 20px;
  }
`

const Description = styled.p`
  color: white;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.5px;
  opacity: 0.6;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
    line-height: 25px;
    margin-top: 20px;
    word-break: keep-all;
  }
`

const Form = styled.form`
  display: flex;
  width: 100%;
  margin-top: 50px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    flex-direction: column;
  }
`

const Input = styled.input`
  flex-grow: 1;
  color: white;
  background-color: black;
  padding: 17px 0 18px 20px;
  border-radius: 10px;
  border: solid 2px #823fc2;
  font-size: 23px;
  line-height: 40px;

  &:active,
  &:focus,
  &:invalid {
    box-shadow: none;
    outline: none;
  }

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    height: 57px;
    padding: 5px 5px 0 16px;
    font-size: 16px;
    line-height: 21px;
  }
`
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 75px;
  color: white;
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  border-radius: 10px;
  margin-left: 10px;
  background-image: linear-gradient(298deg, #b882ff 107%, #823fc2, #6c19bc);

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    width: 100%;
    height: 54px;
    font-size: 16px;
    line-height: 21px;
    margin-left: 0;
    margin-top: 12px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(280deg, #b882ff 109%, #823fc2, #6c19bc);
  }
`

const Stories = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 50px;
  z-index: 1000;
  margin-top: 50px;
`

const Story = styled.li`
  background-color: white;
  font-size: 23px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #7b7880;
  padding: 17px 32px 18px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  text-align: left;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export default memo(
  ({ onClickContribute }: { onClickContribute: () => void }) => {
    const { openModal } = useModalStore()
    const {
      state: { show: loading },
      start: startLoading,
      finish: finishLoading,
    } = useLoadingStore()
    const [stories, setStories] = useState([])
    const [text, setText] = useState("")
    const handleChange = useCallback(e => {
      setText(e.target.value)
    }, [])

    const fetchStories = useCallback(() => {
      api.getStories().then(result => {
        setStories(result.data.stories)
      })
    }, [api.getStories, setStories])

    useEffect(() => {
      fetchStories()
    }, [])

    const handleSubmit = useCallback(
      e => {
        e.preventDefault()
        if (text && text.length > 4 && text.length < 31) {
          startLoading()
          api
            .submitStory(text)
            .then(() => {
              fetchStories()
              finishLoading()
              openModal(
                <ShareEventModal
                  onClickContribute={onClickContribute}
                  text={text}
                />
              )
            })
            .catch(e => {
              finishLoading()
              window.alert(e.message)
            })
        } else {
          window.alert("5자 이상, 30자 이내로 입력해주세요!")
        }
      },
      [onClickContribute, openModal, text]
    )

    return (
      <Self id="your-story">
        <InnerSection>
          <SubTitle>‘2021년, 이건 꼭 달라져야 하니까’</SubTitle>
          <Title>
            닷페가 전해주길 바라는
            <br />
            변화에 대해 <Br mobile />
            이야기해주세요
          </Title>
          <Description>
            바뀌길 원하는 제도, 들렸으면 좋겠는 소식을 닷페이스 팀원들에게
            알려주세요.
            <Br desktop /> 2021년 이런 변화를 더 주목하고 이야기를 찾아 전할게요
          </Description>

          <Stories>
            {stories.map((s, index) => (
              <Story key={index}>{s.text}</Story>
            ))}
          </Stories>
        </InnerSection>
        <DimLayer />
      </Self>
    )
  }
)
