import React, { memo, useCallback, useEffect, useState } from "react"
import Bowser from "bowser"
import { graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import Layout from "~components/layout"
import ContributeModal from "~components/modals/contribute"
import {
  useContributeStore,
  useLoadingStore,
  useModalStore,
} from "~context/stores"
import styled from "~context/theme"
import AboutGoods from "~sections/about-goods"
import Jumbotron from "~sections/jumbotron"
import Intro from "~sections/intro"
import WeDidButDidnt from "~sections/we-did-but-we-didnt"
import Contribute from "~sections/contribute"
import PeopleWords from "~sections/people-words"
import Progress from "~sections/progress"
import ShareEvent from "~sections/share-event"
import YourStories from "~sections/your-stories"
import * as api from "~utils/api"

const Index: React.FC = memo(() => {
  const { init, loginWithEmail } = useContributeStore()
  const { openModal } = useModalStore()
  const { finishMotion } = useLoadingStore()
  const [peopleCount, setPeopleCount] = useState(0)
  const [email_token] = useQueryParam("email_token", StringParam)

  const handleAfterHide = useCallback(() => {
    init()
  }, [init])
  const loadVideo = fileName => {
    const req = new XMLHttpRequest()
    req.open("GET", fileName, true)
    req.responseType = "blob"

    req.onload = function () {
      if (this.status === 200) {
        finishMotion()
      }
    }

    req.onerror = function () {
      finishMotion()
    }

    req.send()
  }

  useEffect(() => {
    const ua = Bowser.parse(window.navigator.userAgent)
    const browserName = ua.browser.name
    const platform = ua.platform.type

    let fileName = "jumbotron-mobile.webp"
    if (browserName === "Safari") {
      fileName = `jumbotron-${platform}.mp4`
    } else {
      fileName = `jumbotron-${platform}.webp`
    }

    loadVideo(`images/${fileName}`)

    api
      .fetchPeopleCount()
      .then(({ data: { n_people } }) => {
        setPeopleCount(n_people)
      })
      .catch(e => {
        console.error(e)
      })

    if (email_token) {
      loginWithEmail(email_token)
        .then(result => {
          if (result.success) {
            openModal(<ContributeModal isOld key="contribute-modal" />)
          } else {
            window.alert(Object.values(result.errors).join("\n"))
          }
        })
        .catch(() => {
          window.alert("잘못된 접근입니다.")
        })
    }
  }, [])

  const handleClickContribute = useCallback(() => {
    openModal(<ContributeModal key="contribute-modal" />, {
      onAfterHide: handleAfterHide,
    })
  }, [openModal])

  return (
    <Layout>
      <Jumbotron />
      <Intro />
      <Progress count={peopleCount} />
      <WeDidButDidnt />
      <Contribute onClickContribute={handleClickContribute} />
      {/* <PeopleWords /> */}
      {/* <AboutGoods onClickContribute={handleClickContribute} /> */}
      <YourStories onClickContribute={handleClickContribute} />
      {/* <ShareEvent /> */}
    </Layout>
  )
})

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
