import React, { HTMLAttributes, memo } from "react"
import {
  InnerSection as InnerSectionOrig,
  SectionWrapper as SectionWrapperOrig,
} from "../components/common"
import styled from "../context/theme"

const Self = styled(SectionWrapperOrig)`
  padding: 80px 0 60px;
  background-image: linear-gradient(to left, #ddcaa6, #bb6ae5);

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    padding: 50px 0 50px;
  }
`

const InnerSection = styled(InnerSectionOrig)``

const Title = styled.h2`
  color: white;
  font-size: 50px;
  font-weight: bold;
  line-height: 70px;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 14px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -2px;
    margin-top: 17px;
  }
`

const SubTitle = styled.div`
  color: white;
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: -0.5px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.5px;
    margin-top: 10px;
  }
`

const Description = styled.p`
  color: white;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 30px;
  word-break: keep-all;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.5px;
    margin-top: 28px;
  }
`

const Button = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.purple1};
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: -0.5px;
  background-color: white;
  padding-top: 17px;
  padding-bottom: 17px;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 80%;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 18px;
    line-height: 32px;
  }
`

const TitleBr = styled.br<{ mobile?: boolean }>`
  ${({ mobile }) =>
    mobile
      ? `
    display: none;
  `
      : `
    display: block;
  `}

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    ${({ mobile }) =>
      mobile
        ? `
      display: block;
    `
        : `
      display: none;
    `}
  }
`

const Contribute: React.FC<
  HTMLAttributes<HTMLElement> & {
    onClickContribute: () => void
  }
> = memo(({ onClickContribute, ...props }) => (
  <Self id="contribute" {...props}>
    <InnerSection>
      <SubTitle>우리가 살아갈 세상은 달라야 하니까,</SubTitle>
      <Title>
        닷페이스는 아직
        <TitleBr /> 해야할
        <TitleBr mobile /> 이야기가 많습니다
      </Title>
      <Description>
        좋은 이야기는 더 많은 이야기를 부르고, 우리가 바라는 미래를 앞당겨 오는
        힘을 만듭니다. 우리가 살아 갈 세상을 다르게 만들 수 있도록, 닷페이스의
        후원자로 함께하세요.
        <br />
        <br />
        *연말캠페인은 종료되었지만 여전히 닷페이스 홈페이지를 통해 닷페이스를
        후원하실 수 있어요!
      </Description>
      <a href="https://membership.dotface.kr" target="_blank">
        <Button>닷페이스 후원하기</Button>
      </a>
    </InnerSection>
  </Self>
))

export default Contribute
