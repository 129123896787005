import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import differenceInDays from "date-fns/differenceInDays"
import CountUp from "react-countup"
import { css } from "@emotion/core"
import {
  Br,
  InnerSection as InnerSectionOrig,
  SectionWrapper as SectionWrapperOrig,
} from "../components/common"
import styled from "../context/theme"

const Self = styled(SectionWrapperOrig)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: ${({ theme }) => theme.colors.gray2};
`

const InnerSection = styled(InnerSectionOrig)`
  text-align: left;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const CurrentStatus = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const CurrentStatusText = styled.div`
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  text-align: left;

  &:not(:first-of-type) {
    margin-left: 30px;
  }

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 18px;

    &:not(:first-of-type) {
      margin-left: 0px;
    }
  }
`

const Number = styled.span`
  color: ${({ theme }) => theme.colors.purple1};
`

const CampaignTitle = styled.h3`
  font-size: 26px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: -0.57px;
  margin-top: 40px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 20px;
    letter-spacing: -0.5px;
  }
`

const CampaignDescription = styled.p`
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.39px;
  margin-top: 12px;

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
`

const ProgressBarSelf = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.gray1};

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    height: 50px;
  }
`

const Progress = styled.div<{ percentage: number }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.percentage}%;
  color: white;
  font-size: 23px;
  font-weight: bold;
  line-height: 40px;
  padding-right: 10px;
  background-color: ${({ theme }) => theme.colors.gray1};
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  background-image: linear-gradient(to left, #813fc2, #bb6ae5);
  transition: width 3s cubic-bezier(0.075, 0.82, 0.165, 1);
`

const PercentageText = styled.span<{ percentage: number }>`
  ${({ theme, percentage }) =>
    percentage < 30 &&
    css`
      color: ${theme.colors.purple1};
      margin-right: -60px;
    `}

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: -0.43px;
  }
`

const ProgressBar = memo(({ percentage = 0 }: { percentage: number }) => {
  return (
    <ProgressBarSelf>
      <Progress percentage={percentage}>
        <PercentageText percentage={percentage}>{percentage}%</PercentageText>
      </Progress>
    </ProgressBarSelf>
  )
})

const ContributeArrow = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContributeArrowText = styled.a`
  color: ${({ theme }) => theme.colors.purple1};
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px;
`

const ArrowDown = styled.div`
  width: 20px;
  height: 9px;
  background-image: url("images/progress-arrow-down-purple.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 12px;
`

export default memo(({ count = 0 }: { count: number }) => {
  const ref = useRef(null)
  const observerRef = useRef(null)
  const countRef = useRef(0)

  const handleIOCallback = useCallback(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        observerRef.current.unobserve(ref.current)
      }
    })
  }, [])

  const observe = useCallback(() => {
    const options = {
      threshold: 0,
    }
    observerRef.current = new IntersectionObserver(handleIOCallback, options)
    observerRef.current.observe(ref.current)
  }, [])

  useEffect(() => {
    observe()
  }, [])

  useEffect(() => {
    countRef.current = count
    observe()
  }, [count])

  return (
    <Self>
      <InnerSection>
        <CurrentStatus ref={ref}>
          <CurrentStatusText>
            현재 함께하는 닷페피플{" "}
            <Number>
              <CountUp
                formattingFn={(value: number) => value.toLocaleString()}
                duration={2}
                end={1710}
              />
            </Number>
            /
            <span
              css={css`
                color: #7b7880;
              `}
            >
              2,021명
            </span>
          </CurrentStatusText>
        </CurrentStatus>
      </InnerSection>
      <ProgressBar percentage={Math.round((1710 * 100) / 2021)} />
      <InnerSection>
        <CampaignTitle>연말 후원 캠페인이 마감되었습니다!</CampaignTitle>
        <CampaignDescription>
          캠페인은 마감되었지만 여전히 닷페이스 홈페이지를 통해 후원하실 수
          있어요!
          <Br desktop mobile />
          우리가 2021년에 전해야 할 많은 이야기를 세상 밖으로 나오게 하는 데
          기여하실 수 있습니다.
        </CampaignDescription>
      </InnerSection>
      <ContributeArrow
        href="https://membership.dotface.kr"
        target="_blank"
      >
        <ContributeArrowText>닷페이스 후원하기 👉</ContributeArrowText>
      </ContributeArrow>
    </Self>
  )
})
