type LOGIN_STATUS = {
  status: "connected" | "not_authorized" | "unknown"
  authResponse: {
    accessToken: string
    expiresIn: number
    reauthorize_required_in: number
    signedRequest: string
    userID: string
  }
}

export const getLoginStatus: () => Promise<LOGIN_STATUS> = () => {
  return new Promise(resolve => {
    ;(window as any).FB.getLoginStatus((response: LOGIN_STATUS) => {
      resolve(response)
    })
  })
}

export const login: () => Promise<LOGIN_STATUS> = () => {
  return new Promise(resolve => {
    ;(window as any).FB.login(
      (response: LOGIN_STATUS) => {
        resolve(response)
      },
      { scope: "email" }
    )
  })
}

export const share: ({
  hashtag,
  href,
  quote,
}: {
  hashtag?: string[]
  href: string
  quote?: string
}) => Promise<any> = ({
  hashtag,
  href,
  quote,
}: {
  hashtag?: string[]
  href: string
  quote?: string
}) => {
  return new Promise(resolve => {
    ;(window as any).FB.ui(
      {
        method: "share",
        href,
        hashtag: hashtag ? hashtag.map(h => `#${h}`).join(" ") : null,
      },
      response => {
        resolve(response)
      }
    )
  })
}
