import { keyframes } from "@emotion/core"
import styled, { CreateStyled } from "@emotion/styled"

type Theme = {
  animations: {
    [key: string]: any
  }
  colors: {
    [key: string]: string
  }
  fontWeight: {
    bold: number
    medium: number
  }
  size: {
    maxWidth: number
  }
}

const fadeInOut = keyframes`
from {
  opacity: 1;
}

50% {
  opacity: 0;
}

to {
  opacity: 1;
}
`

export const theme: Theme = {
  animations: {
    fadeInOut,
  },
  colors: {
    black1: "#222026",
    black2: "#353435",
    blue: "#5555ff",
    pink: "#ef588a",
    primary: "#6c19bc",
    purple: "#55009e",
    purple1: "#823fc2",
    purple2: "#bb6ae5",
    white: "#ffffff",
    gray1: "#cbc8d1",
    gray2: "#e8e6eb",
    gray3: "#7b7880",
    gray4: "#a9a6ad",
    gray5: "#434247",
  },
  fontWeight: {
    bold: 700,
    medium: 500,
  },
  size: {
    maxWidth: 768,
  },
}

export default styled as CreateStyled<Theme>
